import * as React from 'react';
import { BarChart } from '@mui/x-charts/BarChart';

import './HorizontalBars.css';
import { Paper, Box, Typography, Divider } from '@mui/material';

import { useTheme } from '@mui/material/styles';
import { axisClasses } from '@mui/x-charts/ChartsAxis';
import { useEffect } from 'react';


const valueFormatter = (value) => `${value}`;

export default function HorizontalBars({ data, showTitle = false }) {
  const theme = useTheme();

  const chartSetting = { 
    xAxis: [
      {
        label: data.xAxisTitle || 'Keywords',
      },
    ],
    height: 400,
  };

// prime data for barchart use
const reducedData = data.data.map(item => ({
  [data.yAxis[0].dataKey]: item[data.yAxis[0].dataKey],
  views: item.views.value,
  keywords: item.keywords.value,
  dateCollected: item.dateCollected.value,
}));

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      minHeight: 412,
      height: '100% !important',
    }} className='HorizontalBars'>
      {showTitle ? 
      <>
        <Box sx={{
          padding: '1rem',
          justifySelf: 'flex-start',
          alignSelf: 'flex-start',
        }}>
          <Typography variant="h4" textAlign={'left'}>
            {data.title}
          </Typography>
        </Box>
        <Divider sx={{
          width: '100%'
        }} />
      </> : ''}
      <BarChart
        sort={data.sort || [{ dataKey: 'engagements', order: 'desc' }]}
        dataset={reducedData}
        yAxis={data.yAxis || [{ scaleType: 'band', dataKey: 'newsSource' }]}
        series={data.series}
        layout={data.layout || 'horizontal'}
        {...chartSetting}
        margin={{ left: 100 }}
        sx={{
          [`.${axisClasses.left} .${axisClasses.label}`]: {
            // Move the y-axis label with CSS
            transform: 'translateX(-80px)',
          },
        }}
        colors={[
          theme.customColors.newYorkPink,
          theme.customColors.barleyCorn,
          theme.customColors.dustStorm,
          theme.customColors.rajah,
          theme.customColors.mountbattenPink
        ]}
      />
    </Box>
  );
}