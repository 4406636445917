import { Box, Container, Divider, Grid, Paper, Typography } from "@mui/material";
import DropSelect from "../../components/DropSelect/DropSelect";
import HorizontalBars from "../../components/HorizontalBars/HorizontalBars";
import MediaTable from "../../components/MediaTable/MediaTable";
import SummaryTable from "../../components/SummaryTable/SummaryTable";
import DateRangePicker from "../../components/DateRangePicker/DateRangePicker";
import Keywords from "../../components/Keywords/Keywords";
import MultiAxisLineChart from "../../components/LineChart/LineChart";
import { observer } from "mobx-react";
import rootStore from "../../store/rootStore";
import DonutChart from "../../components/DonutChart/DonutChart";
import { useEffect } from "react";
import SummaryCard from '../../components/SummaryCard/SummaryCard'
import CompetitorSummary from "../../components/CompetitorSummary/CompetitorSummary";
import ShareOfVoice from "../../components/ShareOfVoice/ShareOfVoice";

const keywordGroups = [
  'Komercijalna Banka',
  'NLB Banka',
  'Societe Generale Banka',
]

function KeywordTracker() {
  console.log('rootStore.selectedKeywordGroup', rootStore.selectedKeywordGroup)

  useEffect(() => {
    console.log('rootStore.selectedSentiment', rootStore.selectedSentiment)
  }, [rootStore.selectedSentiment]);

  return (
    <Container sx={{
      paddingTop: 2,
      display: 'grid',
      gridTemplateAreas: `
        "keywordGroup keywordGroup keywordGroup keywords"
        "mediatable mediatable mediatable mediatable"
        "summarytable summarytable summarytable donutchart"
        "linechart linechart linechart linechart"
        "horizontalbars horizontalbars horizontalbars horizontalbars"
      `,
      gridTemplateColumns: '1fr 1fr 1fr 1fr',
      gridTemplateRows: '1fr auto auto auto auto auto auto',
      gap: 1,
      '@media (max-width: 900px)': {
        gridTemplateAreas: `
          "keywordGroup"
          "keywordGroup"
          "keywords"
          "mediatable"
          "summarytable"
          "linechart"
          "donutchart"
          "horizontalbars"
        `,
        gridTemplateColumns: '1fr',
        gridTemplateRows: 'auto auto auto auto auto auto auto auto',
      }
    }}>
      <Paper sx={{
        gridArea: 'keywordGroup',
        display: 'flex',
        flexDirection: 'column',
        p: 1.5,
        gap: 2,
        // maxHeight: '120px'
      }}>
        <Typography variant='subtitle1'>Keyword tracker for:</Typography>
        <DropSelect keywordGroups={rootStore.keywordGroups} />
        <DateRangePicker />
      </Paper>
      {/* <Paper sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          p: 1.5,
          gap: 1,
          gridArea: 'dateRangePicker',
        }}>
        <Typography variant='h5'>
          Pick Dates: 
          </Typography>
        <DateRangePicker />
      </Paper> */}
      <Paper sx={{
          p: 1.5,
          gap: 1,
          gridArea: 'keywords',
        }}>
          <Keywords />
      </Paper>
      <Box sx={{ gridArea: 'mediatable' }}>
        <MediaTable rows={rootStore.selectedKeywordGroup.mediaTableData} />
      </Box>
      <Box sx={{ gridArea: 'summarytable' }}>
        <SummaryTable />
      </Box>
      <Paper sx={{ gridArea: 'linechart' }}>
        <MultiAxisLineChart data={rootStore.selectedKeywordGroup.keywordEngagementData} yLabel={'Keyword Engagements'} />
      </Paper>
      <Paper sx={{
        gridArea: 'donutchart',
        height: '100%'
      }}>
        <DonutChart sentimentData={rootStore.selectedKeywordGroup.sentiment} sentimentScore={rootStore.selectedKeywordGroup.sentimentScore} />
      </Paper>
      <Paper sx={{ gridArea: 'horizontalbars' }}>
        <HorizontalBars showTitle={true} data={rootStore.selectedKeywordGroup.newsOutletsData} />
      </Paper>
    </Container>
  );
}

export default observer(KeywordTracker);
