import { createTheme } from '@mui/material';

const mainColorsPalette = {
  newYorkPink: '#dc828f',
  barleyCorn: '#9c9359',
  dustStorm: '#e8d6cf',
  rajah: '#f7ce76',
  mountbattenPink: '#8c7386'
}

const colors = {
  white: '#ffffff',
  darkWhite: '#f8f8f8',
  newYorkPink: '#dc828f',
  barleyCorn: '#9c9359',
  dustStorm: '#e8d6cf',
  rajah: '#f7ce76',
  mountbattenPink: '#8c7386',
  deepBlue: '#162542',
  grayBlue: '#e8eff2',
  badBlue: '#7b8bad',
  black: '#000000',
  black1: '#162542',
  black2: '#3F3F3F',
  black3: 'rgba(255, 255, 255, 0.2)',
  black4: 'rgba(255, 255, 255, 0.1)',
  grey1: '#666666',
  grey2: '#989898',
  grey3: '#EBEBEB',
  grey4: '#E5E5E5',
  grey5: '#CBCBCB',
  grey6: '#F4F4F4',
  grey7: '#F8F8F8',
  grey8: '#9E9E9E',
  red: '#E84141',
  green: '#26BD7E',
  blue: '#1C46DB',
  yellow: '#FFDB58',
};

const palette = {
  action: {
    disabled: colors.grey2,
    disabledBackground: colors.grey3
  },
  background: {
    main: colors.dustStorm,
    secondary: colors.white,
    dark: colors.grayBlue,
    darkSelect: colors.black4,
    paper: colors.grey4,
    box: colors.grey4
  },
  primary: {
    main: colors.barleyCorn,
    light: colors.lightOrange,
    dark: colors.lightOrange,
    contrastText: colors.white,
  },
  secondary: {
    main: colors.newYorkPink,
    light: colors.grayBlue,
    dark: colors.badBlue,
    contrastText: colors.white,
  },
  select: {
    contrastText: colors.black
  },
  text: {
    primary: colors.black,
    secondary: colors.badBlue,
    contrastText: colors.white
  },
  common: colors,
  error: {
    main: colors.red,
    contrastText: colors.white
  },
  success: {
    main: colors.green,
    contrastText: colors.white
  },
  warning: {
    main: colors.yellow,
    contrastText: colors.white
  },
  info: {
    main: colors.blue,
    contrastText: colors.white
  },
  notdone: {
    main: '#2F4858',
    contrastText: colors.white
  },
  mode: 'light'
};

const typography = {
  fontFamily: 'Barlow, Roboto, Poppins, sans-serif',
  fontSize: 13,
  h1: {
    fontSize: '1.5rem',
    fontWeight: 600,
    textTransform: 'uppercase',
    fontFamily: 'Barlow, Roboto, sans-serif',
    color: colors.black,
  },
  h2: {
    fontSize: '1.3rem',
    fontWeight: 600,
    textTransform: 'uppercase',
    fontFamily: 'Barlow, Roboto, sans-serif',
    color: colors.black,
  },
  h3: {
    fontSize: '1.2rem',
    fontWeight: 600,
    textTransform: 'uppercase',
    fontFamily: 'Barlow, Roboto, sans-serif',
    color: colors.black,
  },
  h4: {
    fontSize: '1.1rem',
    fontWeight: 600
  },
  h5: {
    fontSize: '1rem',
    fontWeight: 600
  },
  h6: {
    fontSize: '0.9rem',
    fontWeight: 600
  },
  body1: {
    fontSize: '0.9rem',
    fontFamily: 'Barlow, Roboto, sans-serif',
    fontWeight: 500,
  },
  body2: {
    fontSize: '0.8rem',
    fontFamily: 'Barlow, Roboto, sans-serif',
    fontWeight: 500,
  },
  button: {
    fontSize: '0.8rem',
    fontWeight: 600
  },
  chart: {
    fontSize: '1rem',
    fontFamily: 'Barlow, Roboto, sans-serif',
    fontWeight: 500,
  },
};

const theme = createTheme({
  palette,
  typography,
  components: {
    MuiIconButton: {
      styleOverrides: {
        root: {
          background: colors.white,
          color: colors.white,
          padding: '5px',
          margin: '5px'
        }
      }
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          backgroundColor: 'transparent'
        },
      }
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: colors.black,
          background: 'transparent',
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: 10,
        },
        elevation: {
          7: {
            boxShadow: `0 0 15px ${palette.primary.main}`,
          }
        }
      }
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          border: 'none',
          borderRadius: 20,
        },
      }
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          borderRadius: 10,
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          borderRadius: 10,
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: 10,
        }
      }
    },
  },
  customColors: {
    ...colors,
    ...mainColorsPalette
  }
});

export default theme;