const routes = [
  {
    path: '/',
    name: 'Welcome',
    component: 'Home',
  },
  {
    path: '/keyword-tracker',
    name: 'Keyword Tracker',
    component: 'KeywordTracker',
  },
  {
    path: '/competitors',
    name: 'Competitors',
    component: 'Competitors',
  },
  {
    path: '/analytics',
    name: 'Analytics',
    component: 'Analytics',
  },
  {
    path: '/reports',
    name: 'Reports',
    component: 'Reports',
  }
]

const authRoutes = [
  {
    path: '/login',
    name: 'Login',
    component: 'Login',
  },
  {
    path: '/register',
    name: 'Register',
    component: 'Register',
  },
]

export default routes;
