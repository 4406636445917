import axios from 'axios';

class HttpService {
  constructor() {
    this.instance = axios.create({
      baseURL: 'https://api.devvinci.com',
    });
  }

  get(url) {
    return this.instance.get(url);
  }

  post(url, data) {
    return this.instance.post(url, data);
  }

  put(url, data) {
    return this.instance.put(url, data);
  }

  delete(url) {
    return this.instance.delete(url);
  }
}

const httpService = new HttpService();
export default httpService;
