import { makeAutoObservable } from 'mobx'
import routes from './routes'
import { Source } from '@mui/icons-material'
import dayjs from 'dayjs';
import { applyInitialState } from '@mui/x-data-grid/hooks/features/columns/gridColumnsUtils';
import httpService from '../services/httpService';
import axios from 'axios';


class RootStore {
  dateToday = dayjs();
  date5DaysAgo = dayjs().subtract(5, 'day');

  isDrawerOpen = true;

  toggleDrawer() {
    this.isDrawerOpen = !this.isDrawerOpen;
  }

  keywordGroups = [
    {
      groupName: 'Komercijalna Banka',
      dateRange: [
        this.date5DaysAgo,
        this.dateToday
      ],
      keywords: [
        'Mastercard',
        'Visa',
        'PayPal',
        'Stripe',
        'Square'
      ],
      totalCompetitorArticles: {
        title: 'Articles',
        value: 300,
        percentage: 1.5,
      },
      totalCompetitorMentions: {
        title: 'Mentions',
        value: 200,
        percentage: 13.5,
      },
      totalCompetitorSocialPosts: {
        title: 'Social Posts',
        value: 100,
        percentage: 2.0,
      },
      totalArticles: {
        title: 'Articles',
        value: 350,
        percentage: 1.5,
      },
      totalMentions: {
        title: 'Mentions',
        value: 250,
        percentage: 13.5,
      },
      totalSocialPosts: {
        title: 'Social Posts',
        value: 100,
        percentage: 2.0,
      },
      totalLikes: {
        title: 'Likes',
        value: 400,
        percentage: 30.34,
      },
      totalEngagements: {
        title: 'Engagements',
        value: 500,
        percentage: 40.5,
      },
      totalComments: {
        title: 'Comments',
        value: 600,
        percentage: 12.6,
      },
      totalShares: {
        title: 'Shares',
        value: 700,
        percentage: 10.2,
      },
      newsOutletsData: {
        layout: "horizontal",
        title: 'Most active source by keyword tracker',
        xAxisTitle: 'Engagements / Keywords',
        sort: [{ dataKey: 'engagements', order: 'desc' }],
        series: [
          { dataKey: 'views', label: 'Engagements' },
          { dataKey: 'keywords', label: 'Keywords mentioned' },
        ],
        yAxis: [{ scaleType: 'band', dataKey: 'newsSource', label: 'Source' }],
        data: [
          {
            newsSource: 'Sloboden Pecat',
            views: {
              dataKey: 'views',
              label: 'Engagements',
              value: 59,
            },
            keywords: {
              dataKey: 'keywords',
              label: 'Keywords',
              value: 5,
            },
            dateCollected: {
              dataKey: 'dateCollected',
              label: 'Date',
              value: '2024-10-01',
            },
          },
          {
            newsSource: 'Republika',
            views: {
              label: 'Engagements',
              value: 57,
            },
            keywords: {
              label: 'Keywords',
              value: 3,
            },
            dateCollected: {
              label: 'Date',
              value: '2024-10-01',
            },
          },
          {
            newsSource: 'Info.mk',
            views: {
              label: 'Engagements',
              value: 86,
            },
            keywords: {
              label: 'Keywords',
              value: 8,
            },
            dateCollected: {
              label: 'Date',
              value: '2024-10-01',
            },
          }
        ],
      },
      mediaOutletsData: {
        layout: "horizontal",
        title: 'Most Active Media Outlet',
        xAxisTitle: 'Engagements / Keywords',
        sort: [{ dataKey: 'engagements', order: 'asc' }],
        series: [
          { dataKey: 'views', label: 'Engagements' },
          { dataKey: 'keywords', label: 'Keywords mentioned' },
        ],
        yAxis: [{ scaleType: 'band', dataKey: 'mediaSource', label: 'Source' }],
        data: [
          {
            mediaSource: 'Instagram',
            views: {
              dataKey: 'views',
              label: 'Engagements',
              value: 59,
            },
            keywords: {
              dataKey: 'keywords',
              label: 'Keywords',
              value: 5,
            },
            dateCollected: {
              dataKey: 'dateCollected',
              label: 'Date',
              value: '2024-10-01',
            },
          },
          {
            mediaSource: 'Facebook',
            views: {
              label: 'Engagements',
              value: 57,
            },
            keywords: {
              label: 'Keywords',
              value: 3,
            },
            dateCollected: {
              label: 'Date',
              value: '2024-10-01',
            },
          },
          {
            mediaSource: 'Twitter',
            views: {
              label: 'Engagements',
              value: 86,
            },
            keywords: {
              label: 'Keywords',
              value: 8,
            },
            dateCollected: {
              label: 'Date',
              value: '2024-10-01',
            },
          }
        ],
      },
      mostEngagingOutletOnFb: {
        layout: "horizontal",
        title: 'Most Engaging Outlet on FB',
        xAxisTitle: 'Engagements / Keywords',
        sort: [{ dataKey: 'engagements', order: 'asc' }],
        series: [
          { dataKey: 'views', label: 'Engagements' },
          { dataKey: 'keywords', label: 'Keywords mentioned' },
        ],
        yAxis: [{ scaleType: 'band', dataKey: 'mediaSource', label: 'Source' }],
        data: [
          {
            mediaSource: 'Pari.mk',
            views: {
              dataKey: 'views',
              label: 'Engagements',
              value: 59,
            },
            keywords: {
              dataKey: 'keywords',
              label: 'Keywords',
              value: 5,
            },
            dateCollected: {
              dataKey: 'dateCollected',
              label: 'Date',
              value: '2024-10-01',
            },
          },
          {
            mediaSource: 'on.net.mk',
            views: {
              label: 'Engagements',
              value: 57,
            },
            keywords: {
              label: 'Keywords',
              value: 3,
            },
            dateCollected: {
              label: 'Date',
              value: '2024-10-01',
            },
          },
          {
            mediaSource: 'time.mk',
            views: {
              label: 'Engagements',
              value: 86,
            },
            keywords: {
              label: 'Keywords',
              value: 8,
            },
            dateCollected: {
              label: 'Date',
              value: '2024-10-01',
            },
          }
        ],
      },
      mostActiveSourceByCompetitor: {
        layout: "horizontal",
        title: 'Mosta active source by competitor',
        xAxisTitle: 'Engagements / Keywords',
        sort: [{ dataKey: 'engagements', order: 'asc' }],
        series: [
          { dataKey: 'views', label: 'Engagements' },
          { dataKey: 'keywords', label: 'Keywords mentioned' },
        ],
        yAxis: [{ scaleType: 'band', dataKey: 'newsSource', label: 'Source' }],
        data: [
          {
            newsSource: 'Tea',
            views: {
              dataKey: 'views',
              label: 'Engagements',
              value: 59,
            },
            keywords: {
              dataKey: 'keywords',
              label: 'Keywords',
              value: 5,
            },
            dateCollected: {
              dataKey: 'dateCollected',
              label: 'Date',
              value: '2024-10-01',
            },
          },
          {
            newsSource: 'Fokus',
            views: {
              dataKey: 'views',
              label: 'Engagements',
              value: 59,
            },
            keywords: {
              dataKey: 'keywords',
              label: 'Keywords',
              value: 5,
            },
            dateCollected: {
              dataKey: 'dateCollected',
              label: 'Date',
              value: '2024-10-01',
            },
          },
          {
            newsSource: 'Republika',
            views: {
              dataKey: 'views',
              label: 'Engagements',
              value: 59,
            },
            keywords: {
              dataKey: 'keywords',
              label: 'Keywords',
              value: 5,
            },
            dateCollected: {
              dataKey: 'dateCollected',
              label: 'Date',
              value: '2024-10-01',
            },
          },
          {
            newsSource: 'Sloboden Pecat',
            views: {
              dataKey: 'views',
              label: 'Engagements',
              value: 59,
            },
            keywords: {
              dataKey: 'keywords',
              label: 'Keywords',
              value: 5,
            },
            dateCollected: {
              dataKey: 'dateCollected',
              label: 'Date',
              value: '2024-10-01',
            },
          },
          {
            newsSource: 'Republika',
            views: {
              label: 'Engagements',
              value: 57,
            },
            keywords: {
              label: 'Keywords',
              value: 3,
            },
            dateCollected: {
              label: 'Date',
              value: '2024-10-01',
            },
          },
          {
            newsSource: 'Info.mk',
            views: {
              label: 'Engagements',
              value: 86,
            },
            keywords: {
              label: 'Keywords',
              value: 8,
            },
            dateCollected: {
              label: 'Date',
              value: '2024-10-01',
            },
          }
        ],
      },
      dailyPostsDistribution: {
        layout: "vertical",
        title: 'Daily Posts Distribution',
        series: [
          { dataKey: 'count', },
        ],
        xAxis: [
          { scaleType: 'band', dataKey: 'hour', label: 'Hours of Day' }
        ],
        data: [
          { hour: '00:00', count: 10 },
          { hour: '01:00', count: 5 },
          { hour: '02:00', count: 8 },
          { hour: '03:00', count: 3 },
          { hour: '04:00', count: 7 },
          { hour: '05:00', count: 12 },
          { hour: '06:00', count: 15 },
          { hour: '07:00', count: 20 },
          { hour: '08:00', count: 25 },
          { hour: '09:00', count: 30 },
          { hour: '10:00', count: 35 },
          { hour: '11:00', count: 40 },
          { hour: '12:00', count: 45 },
          { hour: '13:00', count: 50 },
          { hour: '14:00', count: 55 },
          { hour: '15:00', count: 60 },
          { hour: '16:00', count: 55 },
          { hour: '17:00', count: 50 },
          { hour: '18:00', count: 45 },
          { hour: '19:00', count: 40 },
          { hour: '20:00', count: 35 },
          { hour: '21:00', count: 30 },
          { hour: '22:00', count: 25 },
          { hour: '23:00', count: 20 },
        ]
      },
      keywordEngagementData: [
        {
          keyword: 'Mastercard',
          engagements: [
            { date: '2024-05-01', value: 0 },
            { date: '2024-06-02', value: 30 },
            { date: '2024-07-03', value: 10 },
            { date: '2024-08-04', value: 70 },
            { date: '2024-09-05', value: 50 },
          ],
        },
        {
          keyword: 'Visa',
          engagements: [
            { date: '2024-05-01', value: 10 },
            { date: '2024-06-02', value: 0 },
            { date: '2024-07-03', value: 20 },
            { date: '2024-08-04', value: 40 },
            { date: '2024-09-05', value: 60 },
          ]
        },
        {
          keyword: 'PayPal',
          engagements: [
            { date: '2024-05-01', value: 30 },
            { date: '2024-06-02', value: 20 },
            { date: '2024-07-03', value: 0 },
            { date: '2024-08-04', value: 60 },
            { date: '2024-09-05', value: 40 },
          ]
        },
        {
          keyword: 'Stripe',
          engagements: [
            { date: '2024-05-01', value: 50 },
            { date: '2024-06-02', value: 40 },
            { date: '2024-07-03', value: 60 },
            { date: '2024-08-04', value: 0 },
            { date: '2024-09-05', value: 100 }
          ]
        },
        {
          keyword: 'Square',
          engagements: [
            { date: '2024-05-01', value: 70 },
            { date: '2024-06-02', value: 60 },
            { date: '2024-07-03', value: 40 },
            { date: '2024-08-04', value: 100 },
            { date: '2024-09-05', value: 0 },
          ]
        }
      ],
      mediaTableData: [
        {
          id: 2,
          sourceName: 'Netpress',
          sourceUrl: 'https://netpress.com.mk/me-iti-formirame-nov-urgenten-internistichki-centar-za-1-godina-napravivme-pozitivni-promeni-vo-zdravstvoto/',
          title: 'Меџити: Формираме нов ургентен интернистички центар, за 1 година направивме позитивни промени во здравството',
          type: 'media',
          date: '2021-10-02',
          engagements: 100,
          likes: 50,
          comments: 20,
          shares: 10,
          keywords: ['Mastercard', 'Paypal'],
        },
        {
          id: 4,
          sourceName: 'Faktor',
          sourceUrl: 'https://faktor.mk/alkaloid-i-komercijalna-banka-doniraat-povekje-od-400000-evra-za-formiranje-na-urgenten-trijazen-centar-na-klinikata-za-toksikologija',
          title: '„Алкалоид“ и „Комерцијална банка“ донираат повеќе од 400.000 евра за формирање на ургентен тријажен центар на клиниката за токсикологија',
          type: 'media',
          engagements: 300,
          likes: 150,
          comments: 75,
          shares: 37,
          date: '2021-10-02',
        },
        {
          id: 5,
          sourceName: 'Faktor',
          sourceUrl: 'https://faktor.mk/koj-plati-270-iljadi-evra-za-1000-akcii-na-komercijalna---nova-blok-transakcija-so-najgolemata-banka-vo-zemjava',
          title: 'КОЈ ПЛАТИ 270 ИЛЈАДИ ЕВРА ЗА 1.000 АКЦИИ НА КОМЕРЦИЈАЛНА - нова блок трансакција со најголемата банка во земјава',
          type: 'media',
          engagements: 262,
          likes: 150,
          comments: 75,
          shares: 37,
          date: '2021-10-02',
        },
        {
          id: 6,
          sourceName: 'Makfax',
          sourceUrl: 'https://makfax.com.mk/makedonija/%d0%bc%d0%b5%d1%9f%d0%b8%d1%82%d0%b8-%d1%84%d0%be%d1%80%d0%bc%d0%b8%d1%80%d0%b0%d0%bc%d0%b5-%d0%bd%d0%be%d0%b2-%d1%83%d1%80%d0%b3%d0%b5%d0%bd%d1%82%d0%b5%d0%bd-%d0%b8%d0%bd%d1%82%d0%b5%d1%80%d0%bd/',
          title: 'Меџити: Формираме нов ургентен интернистички центар, за една година направивме позитивни промени во здравството',
          type: 'media',
          engagements: 204,
          likes: 150,
          comments: 40,
          shares: 14,
          date: '2024-02-06',
          // 16:57
        },
        {
          id: 7,
          sourceName: 'Kanal5',
          sourceUrl: 'https://kanal5.com.mk/medjiti-potpisha-dogovor-so-komercijalna-banka-i-alkaloid-za-nov-urgenten-internistichki-centar/a624585',
          title: 'Меџити потпиша договор со Комерцијална банка и Алкалоид за нов Ургентен интернистички центар',
          type: 'media',
          engagements: 142,
          likes: 80,
          comments: 25,
          shares: 37,
          date: '2024-02-06',
        },
        {
          id: 8,
          sourceName: 'Kanal5',
          sourceUrl: 'https://kanal5.com.mk/potpishan-dogovor-za-nov-urgenten-internistichki-centar-na-toksikologija/a624509',
          title: 'Потпишан договор за нов ургентен интернистички центар на Токсикологија',
          type: 'media',
          engagements: 369,
          likes: 312,
          comments: 45,
          shares: 12,
          date: '2024-02-06',
        },
        {
          id: 9,
          sourceName: 'Press24',
          sourceUrl: 'https://press24.mk/nov-urgenten-internistichki-centar-na-klinikata-za-toksikologija',
          title: 'Нов ургентен интернистички центар на Клиниката за Токсикологија',
          type: 'media',
          engagements: 1756,
          likes: 1314,
          comments: 405,
          shares: 37,
          date: '2024-02-06',
        },
        {
          id: 10,
          sourceName: 'Fokus',
          sourceUrl: 'https://fokus.mk/alkaloid-i-komertsijalna-banka-doniraat-nad-460-000-evra-za-formirane-na-urgenten-trijazhen-tsentar-na-klinika-za-toksikologija/',
          title: '„Алкалоид“ и „Комерцијална банка“ донираат над 460.000 евра за формирање на ургентен тријажен центар на Клиника за токсикологија',
          type: 'media',
          engagements: 300,
          likes: 150,
          comments: 75,
          shares: 37,
          date: '2024-02-06',
        },
        {
          id: 11,
          sourceName: 'Opserver',
          sourceUrl: 'https://opserver.mk/makedonija/medzhiti-formirame-nov-urgenten-internistichki-centar-za-edna-godina-napravivme-pozitivni-promeni-vo-zdravstvoto/',
          title: 'Меџити: Формираме нов ургентен интернистички центар, за една година направивме позитивни промени во здравството',
          type: 'media',
          engagements: 300,
          likes: 150,
          comments: 75,
          shares: 37,
          date: '2024-02-06',
        },
        {
          id: 12,
          sourceName: 'Vecer',
          sourceUrl: 'https://www.vecer.press/%d0%bd%d0%be%d0%b2-%d1%83%d1%80%d0%b3%d0%b5%d0%bd%d1%82%d0%b5%d0%bd-%d0%b8%d0%bd%d1%82%d0%b5%d1%80%d0%bd%d0%b8%d1%81%d1%82%d0%b8%d1%87%d0%ba%d0%b8-%d1%86%d0%b5%d0%bd%d1%82%d0%b0%d1%80-%d0%bd%d0%b0/',
          title: 'Нов ургентен интернистички центар на Токсикологија, парите обезбедени од донации',
          type: 'media',
          engagements: 300,
          likes: 150,
          comments: 75,
          shares: 37,
          date: '2024-02-06',
        },
        {
          id: 13,
          sourceName: '360Stepeni',
          sourceUrl: 'https://360stepeni.mk/potpishan-dogovor-za-nov-urgenten-internistichki-tsentar-na-toksikologija/',
          title: 'Потпишан договор за нов ургентен интернистички центар на Токсикологија',
          type: 'media',
          engagements: 300,
          likes: 150,
          comments: 75,
          shares: 37,
          date: '2024-02-06',
        },
        {
          id: 14,
          sourceName: 'Lokalno',
          sourceUrl: 'https://lokalno.mk/vkupno-468-673-evra-doniraa-od-komercijalna-i-alkaloid-za-formiranje-na-urgenten-trijazhen-centar-na-klinika-za-toksikologija/',
          title: 'Вкупно 468.673 евра донираа од Комерцијална и Алкалоид за формирање на ургентен тријажен центар на Клиника за токсикологија',
          type: 'media',
          engagements: 300,
          likes: 150,
          comments: 75,
          shares: 37,
          date: '2024-02-06',
        },
        {
          id: 15,
          sourceName: 'Denar',
          sourceUrl: 'https://denar.mk/300317/biznis/alkaloid-i-komercijalna-banka-doniraat-povekje-od-chetiristotini-iljadi-evra-za-formiranje-na-urgenten-trijazhen-centar-na-toksikologija',
          title: '„Алкалоид“ и „Комерцијална банка“ донираат повеќе од 400.000 евра за формирање на ургентен тријажен центар на клиниката за токсикологија',
          type: 'media',
          engagements: 300,
          likes: 150,
          comments: 75,
          shares: 37,
          date: '2024-02-06',
        },
        {
          id: 16,
          sourceName: 'Pari',
          sourceUrl: 'https://pari.com.mk/toksikologija-ke-dobie-urgenten/',
          title: 'Токсикологија ќе добие ургентен тријажен центар – Комерцијална банка и „Алкалоид“ донираат над 460 илјади евра',
          type: 'media',
          engagements: 300,
          likes: 150,
          comments: 75,
          shares: 37,
          date: '2024-02-06',
        },
        {
          id: 17,
          sourceName: 'eMagazin',
          sourceUrl: 'https://emagazin.mk/%d1%81%d0%be-%d0%b4%d0%be%d0%bd%d0%b0%d1%86%d0%b8%d1%98%d0%b0-%d0%bd%d0%b0-%d0%b0%d0%bb%d0%ba%d0%b0%d0%bb%d0%be%d0%b8%d0%b4-%d0%b8-%d0%ba%d0%be%d0%bc%d0%b5%d1%80%d1%86%d0%b8%d1%98%d0%b0%d0%bb%d0%bd/',
          title: 'Со донација на Алкалоид и Комерцијална банка ќе се формира нов ургентен интернистички центар на Универзитетската клиника за токсикологија',
          type: 'media',
          engagements: 300,
          likes: 150,
          comments: 75,
          shares: 37,
          date: '2024-02-06',
        }
      ],
      mediaTableDataAnalytics: [
        
      ],
      sentiment: [
        {
          label: 'Positive',
          value: 70
        },
        {
          label: 'Neutral',
          value: 20
        },
        {
          label: 'Negative',
          value: 10
        }
      ],
      sentimentScore: 59,
      shareOfVoice: [
        {
          id: 1,
          label: 'Komercijalna Banka',
          value: 30,
          abbreviation: 'KB'
        },
        {
          id: 2,
          label: 'NLB Banka',
          value: 40,
          abbreviation: 'NLB'
        },
        {
          id: 3,
          label: 'Societe Generale Banka',
          value: 30,
          abbreviation: 'SGB'
        }
      ],
      competitorSummary: [
        {
          id: 1,
          source: 'facebook',
          views: 100,
          posts: 59,
          engagements: 200,
          comments: 50,
          likes: 100,
          shares: 25,
          positiveSentiment: 60,
          neutralSentiment: 30,
          negativeSentiment: 10
        },
        {
          id: 2,
          source: 'instagram',
          views: 200,
          posts: 34,
          engagements: 123,
          comments: 40,
          likes: 80,
          shares: 30,
          positiveSentiment: 70,
          neutralSentiment: 20,
          negativeSentiment: 10
        }
      ],
      competitorMediaTable: [
        {
          id: 2,
          sourceName: 'Netpress',
          sourceUrl: 'https://netpress.com.mk/me-iti-formirame-nov-urgenten-internistichki-centar-za-1-godina-napravivme-pozitivni-promeni-vo-zdravstvoto/',
          title: 'Меџити: Формираме нов ургентен интернистички центар, за 1 година направивме позитивни промени во здравството',
          type: 'media',
          date: '2021-10-02',
          engagements: 100,
          likes: 50,
          comments: 20,
          shares: 10,
          keywords: ['Mastercard', 'Paypal'],
        },
        {
          id: 4,
          sourceName: 'Faktor',
          sourceUrl: 'https://faktor.mk/alkaloid-i-komercijalna-banka-doniraat-povekje-od-400000-evra-za-formiranje-na-urgenten-trijazen-centar-na-klinikata-za-toksikologija',
          title: '„Алкалоид“ и „Комерцијална банка“ донираат повеќе од 400.000 евра за формирање на ургентен тријажен центар на клиниката за токсикологија',
          type: 'media',
          engagements: 300,
          likes: 150,
          comments: 75,
          shares: 37,
          date: '2021-10-02',
        },
        {
          id: 5,
          sourceName: 'Faktor',
          sourceUrl: 'https://faktor.mk/koj-plati-270-iljadi-evra-za-1000-akcii-na-komercijalna---nova-blok-transakcija-so-najgolemata-banka-vo-zemjava',
          title: 'КОЈ ПЛАТИ 270 ИЛЈАДИ ЕВРА ЗА 1.000 АКЦИИ НА КОМЕРЦИЈАЛНА - нова блок трансакција со најголемата банка во земјава',
          type: 'media',
          engagements: 262,
          likes: 150,
          comments: 75,
          shares: 37,
          date: '2021-10-02',
        },
        {
          id: 6,
          sourceName: 'Makfax',
          sourceUrl: 'https://makfax.com.mk/makedonija/%d0%bc%d0%b5%d1%9f%d0%b8%d1%82%d0%b8-%d1%84%d0%be%d1%80%d0%bc%d0%b8%d1%80%d0%b0%d0%bc%d0%b5-%d0%bd%d0%be%d0%b2-%d1%83%d1%80%d0%b3%d0%b5%d0%bd%d1%82%d0%b5%d0%bd-%d0%b8%d0%bd%d1%82%d0%b5%d1%80%d0%bd/',
          title: 'Меџити: Формираме нов ургентен интернистички центар, за една година направивме позитивни промени во здравството',
          type: 'media',
          engagements: 204,
          likes: 150,
          comments: 40,
          shares: 14,
          date: '2024-02-06',
          // 16:57
        },
        {
          id: 7,
          sourceName: 'Kanal5',
          sourceUrl: 'https://kanal5.com.mk/medjiti-potpisha-dogovor-so-komercijalna-banka-i-alkaloid-za-nov-urgenten-internistichki-centar/a624585',
          title: 'Меџити потпиша договор со Комерцијална банка и Алкалоид за нов Ургентен интернистички центар',
          type: 'media',
          engagements: 142,
          likes: 80,
          comments: 25,
          shares: 37,
          date: '2024-02-06',
        },
        {
          id: 8,
          sourceName: 'Kanal5',
          sourceUrl: 'https://kanal5.com.mk/potpishan-dogovor-za-nov-urgenten-internistichki-centar-na-toksikologija/a624509',
          title: 'Потпишан договор за нов ургентен интернистички центар на Токсикологија',
          type: 'media',
          engagements: 369,
          likes: 312,
          comments: 45,
          shares: 12,
          date: '2024-02-06',
        },
        {
          id: 9,
          sourceName: 'Press24',
          sourceUrl: 'https://press24.mk/nov-urgenten-internistichki-centar-na-klinikata-za-toksikologija',
          title: 'Нов ургентен интернистички центар на Клиниката за Токсикологија',
          type: 'media',
          engagements: 1756,
          likes: 1314,
          comments: 405,
          shares: 37,
          date: '2024-02-06',
        },
        {
          id: 10,
          sourceName: 'Fokus',
          sourceUrl: 'https://fokus.mk/alkaloid-i-komertsijalna-banka-doniraat-nad-460-000-evra-za-formirane-na-urgenten-trijazhen-tsentar-na-klinika-za-toksikologija/',
          title: '„Алкалоид“ и „Комерцијална банка“ донираат над 460.000 евра за формирање на ургентен тријажен центар на Клиника за токсикологија',
          type: 'media',
          engagements: 300,
          likes: 150,
          comments: 75,
          shares: 37,
          date: '2024-02-06',
        },
        {
          id: 11,
          sourceName: 'Opserver',
          sourceUrl: 'https://opserver.mk/makedonija/medzhiti-formirame-nov-urgenten-internistichki-centar-za-edna-godina-napravivme-pozitivni-promeni-vo-zdravstvoto/',
          title: 'Меџити: Формираме нов ургентен интернистички центар, за една година направивме позитивни промени во здравството',
          type: 'media',
          engagements: 300,
          likes: 150,
          comments: 75,
          shares: 37,
          date: '2024-02-06',
        },
        {
          id: 12,
          sourceName: 'Vecer',
          sourceUrl: 'https://www.vecer.press/%d0%bd%d0%be%d0%b2-%d1%83%d1%80%d0%b3%d0%b5%d0%bd%d1%82%d0%b5%d0%bd-%d0%b8%d0%bd%d1%82%d0%b5%d1%80%d0%bd%d0%b8%d1%81%d1%82%d0%b8%d1%87%d0%ba%d0%b8-%d1%86%d0%b5%d0%bd%d1%82%d0%b0%d1%80-%d0%bd%d0%b0/',
          title: 'Нов ургентен интернистички центар на Токсикологија, парите обезбедени од донации',
          type: 'media',
          engagements: 300,
          likes: 150,
          comments: 75,
          shares: 37,
          date: '2024-02-06',
        },
        {
          id: 13,
          sourceName: '360Stepeni',
          sourceUrl: 'https://360stepeni.mk/potpishan-dogovor-za-nov-urgenten-internistichki-tsentar-na-toksikologija/',
          title: 'Потпишан договор за нов ургентен интернистички центар на Токсикологија',
          type: 'media',
          engagements: 300,
          likes: 150,
          comments: 75,
          shares: 37,
          date: '2024-02-06',
        },
        {
          id: 14,
          sourceName: 'Lokalno',
          sourceUrl: 'https://lokalno.mk/vkupno-468-673-evra-doniraa-od-komercijalna-i-alkaloid-za-formiranje-na-urgenten-trijazhen-centar-na-klinika-za-toksikologija/',
          title: 'Вкупно 468.673 евра донираа од Комерцијална и Алкалоид за формирање на ургентен тријажен центар на Клиника за токсикологија',
          type: 'media',
          engagements: 300,
          likes: 150,
          comments: 75,
          shares: 37,
          date: '2024-02-06',
        },
        {
          id: 15,
          sourceName: 'Denar',
          sourceUrl: 'https://denar.mk/300317/biznis/alkaloid-i-komercijalna-banka-doniraat-povekje-od-chetiristotini-iljadi-evra-za-formiranje-na-urgenten-trijazhen-centar-na-toksikologija',
          title: '„Алкалоид“ и „Комерцијална банка“ донираат повеќе од 400.000 евра за формирање на ургентен тријажен центар на клиниката за токсикологија',
          type: 'media',
          engagements: 300,
          likes: 150,
          comments: 75,
          shares: 37,
          date: '2024-02-06',
        },
        {
          id: 16,
          sourceName: 'Pari',
          sourceUrl: 'https://pari.com.mk/toksikologija-ke-dobie-urgenten/',
          title: 'Токсикологија ќе добие ургентен тријажен центар – Комерцијална банка и „Алкалоид“ донираат над 460 илјади евра',
          type: 'media',
          engagements: 300,
          likes: 150,
          comments: 75,
          shares: 37,
          date: '2024-02-06',
        },
        {
          id: 17,
          sourceName: 'eMagazin',
          sourceUrl: 'https://emagazin.mk/%d1%81%d0%be-%d0%b4%d0%be%d0%bd%d0%b0%d1%86%d0%b8%d1%98%d0%b0-%d0%bd%d0%b0-%d0%b0%d0%bb%d0%ba%d0%b0%d0%bb%d0%be%d0%b8%d0%b4-%d0%b8-%d0%ba%d0%be%d0%bc%d0%b5%d1%80%d1%86%d0%b8%d1%98%d0%b0%d0%bb%d0%bd/',
          title: 'Со донација на Алкалоид и Комерцијална банка ќе се формира нов ургентен интернистички центар на Универзитетската клиника за токсикологија',
          type: 'media',
          engagements: 300,
          likes: 150,
          comments: 75,
          shares: 37,
          date: '2024-02-06',
        }
      ],
      competitors: [
        "NLB Banka",
        "Societe Generale Banka"
      ]
      // newsOutletsDataCompetitors:
    },
    {
      groupName: 'NLB Banka',
      dateRange: [
        this.date5DaysAgo,
        this.dateToday
      ],
      keywords: [
        'NLBKeyboard1',
        'NLBKeyboard2',
        'NLBKeyboard3',
        'NLBKeyboard4',
        'NLBKeyboard5'
      ],
      totalCompetitorArticles: {
        title: 'Articles',
        value: 362,
        percentage: 1.2,
      },
      totalCompetitorMentions: {
        title: 'Mentions',
        value: 252,
        percentage: 11.5,
      },
      totalCompetitorSocialPosts: {
        title: 'Social Posts',
        value: 110,
        percentage: 1.7,
      },
      totalArticles: {
        title: 'Articles',
        value: 200,
        percentage: 1.6,
      },
      totalMentions: {
        title: 'Mentions',
        value: 120,
        percentage: 16.5,
      },
      totalSocialPosts: {
        title: 'Social Posts',
        value: 80,
        percentage: 2.6,
      },
      totalLikes: {
        title: 'Likes',
        value: 414,
        percentage: 30.35,
      },
      totalEngagements: {
        title: 'Engagements',
        value: 521,
        percentage: 41.5,
      },
      totalComments: {
        title: 'Comments',
        value: 120,
        percentage: 11.6,
      },
      totalShares: {
        title: 'Shares',
        value: 120,
        percentage: 12.2,
      },
      newsOutletsData: {
        layout: "horizontal",
        title: 'Most active source by keyword tracker',
        sort: [{ dataKey: 'engagements', order: 'desc' }],
        series: [
          { dataKey: 'views', label: 'Engagements' },
          { dataKey: 'keywords', label: 'Keywords mentioned' },
        ],
        yAxis: [{ scaleType: 'band', dataKey: 'newsSource' }],
        data: [
          {
            newsSource: 'Sloboden Pecat',
            views: {
              dataKey: 'views',
              label: 'Engagements',
              value: 12,
            },
            keywords: {
              dataKey: 'keywords',
              label: 'Keywords',
              value: 15,
            },
            dateCollected: {
              dataKey: 'dateCollected',
              label: 'Date',
              value: '2024-10-01',
            },
          },
          {
            newsSource: 'Republika',
            views: {
              label: 'Engagements',
              value: 3,
            },
            keywords: {
              label: 'Keywords',
              value: 67,
            },
            dateCollected: {
              label: 'Date',
              value: '2024-10-01',
            },
          },
          {
            newsSource: 'Info.mk',
            views: {
              label: 'Engagements',
              value: 23,
            },
            keywords: {
              label: 'Keywords',
              value: 8,
            },
            dateCollected: {
              label: 'Date',
              value: '2024-10-01',
            },
          }
        ],
      },
      mediaOutletsData: {
        layout: "horizontal",
        title: 'Most Active Media Outlet',
        sort: [{ dataKey: 'engagements', order: 'asc' }],
        series: [
          { dataKey: 'views', label: 'Engagements' },
          { dataKey: 'keywords', label: 'Keywords mentioned' },
        ],
        yAxis: [{ scaleType: 'band', dataKey: 'mediaSource' }],
        data: [
          {
            mediaSource: 'Instagram',
            views: {
              dataKey: 'views',
              label: 'Engagements',
              value: 56,
            },
            keywords: {
              dataKey: 'keywords',
              label: 'Keywords',
              value: 4,
            },
            dateCollected: {
              dataKey: 'dateCollected',
              label: 'Date',
              value: '2024-10-01',
            },
          },
          {
            mediaSource: 'Facebook',
            views: {
              label: 'Engagements',
              value: 52,
            },
            keywords: {
              label: 'Keywords',
              value: 10,
            },
            dateCollected: {
              label: 'Date',
              value: '2024-10-01',
            },
          },
          {
            mediaSource: 'Twitter',
            views: {
              label: 'Engagements',
              value: 23,
            },
            keywords: {
              label: 'Keywords',
              value: 9,
            },
            dateCollected: {
              label: 'Date',
              value: '2024-10-01',
            },
          }
        ],
      },
      mostEngagingOutletOnFb: {
        layout: "horizontal",
        title: 'Most Engaging Outlet on Facebook',
        sort: [{ dataKey: 'engagements', order: 'asc' }],
        series: [
          { dataKey: 'views', label: 'Engagements' },
          { dataKey: 'keywords', label: 'Keywords mentioned' },
        ],
        yAxis: [{ scaleType: 'band', dataKey: 'mediaSource' }],
        data: [
          {
            mediaSource: 'Pari.mk',
            views: {
              dataKey: 'views',
              label: 'Engagements',
              value: 59,
            },
            keywords: {
              dataKey: 'keywords',
              label: 'Keywords',
              value: 5,
            },
            dateCollected: {
              dataKey: 'dateCollected',
              label: 'Date',
              value: '2024-10-01',
            },
          },
          {
            mediaSource: 'on.net.mk',
            views: {
              label: 'Engagements',
              value: 57,
            },
            keywords: {
              label: 'Keywords',
              value: 3,
            },
            dateCollected: {
              label: 'Date',
              value: '2024-10-01',
            },
          },
          {
            mediaSource: 'time.mk',
            views: {
              label: 'Engagements',
              value: 86,
            },
            keywords: {
              label: 'Keywords',
              value: 8,
            },
            dateCollected: {
              label: 'Date',
              value: '2024-10-01',
            },
          }
        ],
      },
      mostActiveSourceByCompetitor: {
        layout: "horizontal",
        title: 'Most Active Source',
        sort: [{ dataKey: 'engagements', order: 'asc' }],
        series: [
          { dataKey: 'views', label: 'Engagements' },
          { dataKey: 'keywords', label: 'Keywords mentioned' },
        ],
        yAxis: [{ scaleType: 'band', dataKey: 'newsSource' }],
        data: [
          {
            newsSource: 'Tea',
            views: {
              dataKey: 'views',
              label: 'Engagements',
              value: 59,
            },
            keywords: {
              dataKey: 'keywords',
              label: 'Keywords',
              value: 5,
            },
            dateCollected: {
              dataKey: 'dateCollected',
              label: 'Date',
              value: '2024-10-01',
            },
          },
          {
            newsSource: 'Fokus',
            views: {
              dataKey: 'views',
              label: 'Engagements',
              value: 59,
            },
            keywords: {
              dataKey: 'keywords',
              label: 'Keywords',
              value: 5,
            },
            dateCollected: {
              dataKey: 'dateCollected',
              label: 'Date',
              value: '2024-10-01',
            },
          },
          {
            newsSource: 'Republika',
            views: {
              dataKey: 'views',
              label: 'Engagements',
              value: 59,
            },
            keywords: {
              dataKey: 'keywords',
              label: 'Keywords',
              value: 5,
            },
            dateCollected: {
              dataKey: 'dateCollected',
              label: 'Date',
              value: '2024-10-01',
            },
          },
          {
            newsSource: 'Sloboden Pecat',
            views: {
              dataKey: 'views',
              label: 'Engagements',
              value: 59,
            },
            keywords: {
              dataKey: 'keywords',
              label: 'Keywords',
              value: 5,
            },
            dateCollected: {
              dataKey: 'dateCollected',
              label: 'Date',
              value: '2024-10-01',
            },
          },
          {
            newsSource: 'Republika',
            views: {
              label: 'Engagements',
              value: 57,
            },
            keywords: {
              label: 'Keywords',
              value: 3,
            },
            dateCollected: {
              label: 'Date',
              value: '2024-10-01',
            },
          },
          {
            newsSource: 'Info.mk',
            views: {
              label: 'Engagements',
              value: 86,
            },
            keywords: {
              label: 'Keywords',
              value: 8,
            },
            dateCollected: {
              label: 'Date',
              value: '2024-10-01',
            },
          }
        ],
      },
      dailyPostsDistribution: {
        layout: "vertical",
        title: 'Daily Posts Distribution',
        series: [
          { dataKey: 'count', },
        ],
        xAxis: [
          { scaleType: 'band', dataKey: 'hour' }
        ],
        data: [
          { hour: '00:00', count: 10 },
          { hour: '01:00', count: 5 },
          { hour: '02:00', count: 8 },
          { hour: '03:00', count: 3 },
          { hour: '04:00', count: 7 },
          { hour: '05:00', count: 12 },
          { hour: '06:00', count: 15 },
          { hour: '07:00', count: 20 },
          { hour: '08:00', count: 25 },
          { hour: '09:00', count: 30 },
          { hour: '10:00', count: 35 },
          { hour: '11:00', count: 40 },
          { hour: '12:00', count: 45 },
          { hour: '13:00', count: 50 },
          { hour: '14:00', count: 55 },
          { hour: '15:00', count: 60 },
          { hour: '16:00', count: 55 },
          { hour: '17:00', count: 50 },
          { hour: '18:00', count: 45 },
          { hour: '19:00', count: 40 },
          { hour: '20:00', count: 35 },
          { hour: '21:00', count: 30 },
          { hour: '22:00', count: 25 },
          { hour: '23:00', count: 20 },
        ]
      },
      keywordEngagementData: [
        {
          keyword: 'Mastercard',
          engagements: [
            { date: '2024-05-01', value: 0 },
            { date: '2024-06-02', value: 30 },
            { date: '2024-07-03', value: 10 },
            { date: '2024-08-04', value: 70 },
            { date: '2024-09-05', value: 50 },
          ],
        },
        {
          keyword: 'Visa',
          engagements: [
            { date: '2024-05-01', value: 10 },
            { date: '2024-06-02', value: 0 },
            { date: '2024-07-03', value: 20 },
            { date: '2024-08-04', value: 40 },
            { date: '2024-09-05', value: 60 },
          ]
        },
        {
          keyword: 'PayPal',
          engagements: [
            { date: '2024-05-01', value: 30 },
            { date: '2024-06-02', value: 20 },
            { date: '2024-07-03', value: 0 },
            { date: '2024-08-04', value: 60 },
            { date: '2024-09-05', value: 40 },
          ]
        },
        {
          keyword: 'Stripe',
          engagements: [
            { date: '2024-05-01', value: 50 },
            { date: '2024-06-02', value: 40 },
            { date: '2024-07-03', value: 60 },
            { date: '2024-08-04', value: 0 },
            { date: '2024-09-05', value: 100 }
          ]
        },
        {
          keyword: 'Square',
          engagements: [
            { date: '2024-05-01', value: 70 },
            { date: '2024-06-02', value: 60 },
            { date: '2024-07-03', value: 40 },
            { date: '2024-08-04', value: 100 },
            { date: '2024-09-05', value: 0 },
          ]
        }
      ],
      mediaTableData: [
        {
          id: 2,
          sourceName: 'Netpress',
          sourceUrl: 'https://netpress.com.mk/me-iti-formirame-nov-urgenten-internistichki-centar-za-1-godina-napravivme-pozitivni-promeni-vo-zdravstvoto/',
          title: 'Меџити: Формираме нов ургентен интернистички центар, за 1 година направивме позитивни промени во здравството',
          type: 'media',
          date: '2021-10-02',
          engagements: 100,
          likes: 50,
          comments: 20,
          shares: 10,
          keywords: ['Mastercard', 'Paypal'],
        },
        {
          id: 4,
          sourceName: 'Faktor',
          sourceUrl: 'https://faktor.mk/alkaloid-i-komercijalna-banka-doniraat-povekje-od-400000-evra-za-formiranje-na-urgenten-trijazen-centar-na-klinikata-za-toksikologija',
          title: '„Алкалоид“ и „Комерцијална банка“ донираат повеќе од 400.000 евра за формирање на ургентен тријажен центар на клиниката за токсикологија',
          type: 'media',
          engagements: 300,
          likes: 150,
          comments: 75,
          shares: 37,
          date: '2021-10-02',
        },
        {
          id: 5,
          sourceName: 'Faktor',
          sourceUrl: 'https://faktor.mk/koj-plati-270-iljadi-evra-za-1000-akcii-na-komercijalna---nova-blok-transakcija-so-najgolemata-banka-vo-zemjava',
          title: 'КОЈ ПЛАТИ 270 ИЛЈАДИ ЕВРА ЗА 1.000 АКЦИИ НА КОМЕРЦИЈАЛНА - нова блок трансакција со најголемата банка во земјава',
          type: 'media',
          engagements: 262,
          likes: 150,
          comments: 75,
          shares: 37,
          date: '2021-10-02',
        },
        {
          id: 6,
          sourceName: 'Makfax',
          sourceUrl: 'https://makfax.com.mk/makedonija/%d0%bc%d0%b5%d1%9f%d0%b8%d1%82%d0%b8-%d1%84%d0%be%d1%80%d0%bc%d0%b8%d1%80%d0%b0%d0%bc%d0%b5-%d0%bd%d0%be%d0%b2-%d1%83%d1%80%d0%b3%d0%b5%d0%bd%d1%82%d0%b5%d0%bd-%d0%b8%d0%bd%d1%82%d0%b5%d1%80%d0%bd/',
          title: 'Меџити: Формираме нов ургентен интернистички центар, за една година направивме позитивни промени во здравството',
          type: 'media',
          engagements: 204,
          likes: 150,
          comments: 40,
          shares: 14,
          date: '2024-02-06',
          // 16:57
        },
        {
          id: 7,
          sourceName: 'Kanal5',
          sourceUrl: 'https://kanal5.com.mk/medjiti-potpisha-dogovor-so-komercijalna-banka-i-alkaloid-za-nov-urgenten-internistichki-centar/a624585',
          title: 'Меџити потпиша договор со Комерцијална банка и Алкалоид за нов Ургентен интернистички центар',
          type: 'media',
          engagements: 142,
          likes: 80,
          comments: 25,
          shares: 37,
          date: '2024-02-06',
        },
        {
          id: 8,
          sourceName: 'Kanal5',
          sourceUrl: 'https://kanal5.com.mk/potpishan-dogovor-za-nov-urgenten-internistichki-centar-na-toksikologija/a624509',
          title: 'Потпишан договор за нов ургентен интернистички центар на Токсикологија',
          type: 'media',
          engagements: 369,
          likes: 312,
          comments: 45,
          shares: 12,
          date: '2024-02-06',
        },
        {
          id: 9,
          sourceName: 'Press24',
          sourceUrl: 'https://press24.mk/nov-urgenten-internistichki-centar-na-klinikata-za-toksikologija',
          title: 'Нов ургентен интернистички центар на Клиниката за Токсикологија',
          type: 'media',
          engagements: 1756,
          likes: 1314,
          comments: 405,
          shares: 37,
          date: '2024-02-06',
        },
        {
          id: 10,
          sourceName: 'Fokus',
          sourceUrl: 'https://fokus.mk/alkaloid-i-komertsijalna-banka-doniraat-nad-460-000-evra-za-formirane-na-urgenten-trijazhen-tsentar-na-klinika-za-toksikologija/',
          title: '„Алкалоид“ и „Комерцијална банка“ донираат над 460.000 евра за формирање на ургентен тријажен центар на Клиника за токсикологија',
          type: 'media',
          engagements: 300,
          likes: 150,
          comments: 75,
          shares: 37,
          date: '2024-02-06',
        },
        {
          id: 11,
          sourceName: 'Opserver',
          sourceUrl: 'https://opserver.mk/makedonija/medzhiti-formirame-nov-urgenten-internistichki-centar-za-edna-godina-napravivme-pozitivni-promeni-vo-zdravstvoto/',
          title: 'Меџити: Формираме нов ургентен интернистички центар, за една година направивме позитивни промени во здравството',
          type: 'media',
          engagements: 300,
          likes: 150,
          comments: 75,
          shares: 37,
          date: '2024-02-06',
        },
        {
          id: 12,
          sourceName: 'Vecer',
          sourceUrl: 'https://www.vecer.press/%d0%bd%d0%be%d0%b2-%d1%83%d1%80%d0%b3%d0%b5%d0%bd%d1%82%d0%b5%d0%bd-%d0%b8%d0%bd%d1%82%d0%b5%d1%80%d0%bd%d0%b8%d1%81%d1%82%d0%b8%d1%87%d0%ba%d0%b8-%d1%86%d0%b5%d0%bd%d1%82%d0%b0%d1%80-%d0%bd%d0%b0/',
          title: 'Нов ургентен интернистички центар на Токсикологија, парите обезбедени од донации',
          type: 'media',
          engagements: 300,
          likes: 150,
          comments: 75,
          shares: 37,
          date: '2024-02-06',
        },
        {
          id: 13,
          sourceName: '360Stepeni',
          sourceUrl: 'https://360stepeni.mk/potpishan-dogovor-za-nov-urgenten-internistichki-tsentar-na-toksikologija/',
          title: 'Потпишан договор за нов ургентен интернистички центар на Токсикологија',
          type: 'media',
          engagements: 300,
          likes: 150,
          comments: 75,
          shares: 37,
          date: '2024-02-06',
        },
        {
          id: 14,
          sourceName: 'Lokalno',
          sourceUrl: 'https://lokalno.mk/vkupno-468-673-evra-doniraa-od-komercijalna-i-alkaloid-za-formiranje-na-urgenten-trijazhen-centar-na-klinika-za-toksikologija/',
          title: 'Вкупно 468.673 евра донираа од Комерцијална и Алкалоид за формирање на ургентен тријажен центар на Клиника за токсикологија',
          type: 'media',
          engagements: 300,
          likes: 150,
          comments: 75,
          shares: 37,
          date: '2024-02-06',
        },
        {
          id: 15,
          sourceName: 'Denar',
          sourceUrl: 'https://denar.mk/300317/biznis/alkaloid-i-komercijalna-banka-doniraat-povekje-od-chetiristotini-iljadi-evra-za-formiranje-na-urgenten-trijazhen-centar-na-toksikologija',
          title: '„Алкалоид“ и „Комерцијална банка“ донираат повеќе од 400.000 евра за формирање на ургентен тријажен центар на клиниката за токсикологија',
          type: 'media',
          engagements: 300,
          likes: 150,
          comments: 75,
          shares: 37,
          date: '2024-02-06',
        },
        {
          id: 16,
          sourceName: 'Pari',
          sourceUrl: 'https://pari.com.mk/toksikologija-ke-dobie-urgenten/',
          title: 'Токсикологија ќе добие ургентен тријажен центар – Комерцијална банка и „Алкалоид“ донираат над 460 илјади евра',
          type: 'media',
          engagements: 300,
          likes: 150,
          comments: 75,
          shares: 37,
          date: '2024-02-06',
        },
        {
          id: 17,
          sourceName: 'eMagazin',
          sourceUrl: 'https://emagazin.mk/%d1%81%d0%be-%d0%b4%d0%be%d0%bd%d0%b0%d1%86%d0%b8%d1%98%d0%b0-%d0%bd%d0%b0-%d0%b0%d0%bb%d0%ba%d0%b0%d0%bb%d0%be%d0%b8%d0%b4-%d0%b8-%d0%ba%d0%be%d0%bc%d0%b5%d1%80%d1%86%d0%b8%d1%98%d0%b0%d0%bb%d0%bd/',
          title: 'Со донација на Алкалоид и Комерцијална банка ќе се формира нов ургентен интернистички центар на Универзитетската клиника за токсикологија',
          type: 'media',
          engagements: 300,
          likes: 150,
          comments: 75,
          shares: 37,
          date: '2024-02-06',
        }
      ],
      sentiment: [
        {
          label: 'Positive',
          value: 70
        },
        {
          label: 'Neutral',
          value: 20
        },
        {
          label: 'Negative',
          value: 10
        }
      ],
      sentimentScore: 37,
      shareOfVoice: [
        {
          id: 1,
          label: 'Komercijalna Banka',
          value: 30,
          abbreviation: 'KB'
        },
        {
          id: 2,
          label: 'NLB Banka',
          value: 40,
          abbreviation: 'NLB'
        },
        {
          id: 3,
          label: 'Societe Generale Banka',
          value: 30,
          abbreviation: 'SGB'
        }
      ],
      competitorSummary: [
        {
          id: 1,
          source: 'facebook',
          views: 100,
          posts: 59,
          engagements: 200,
          comments: 50,
          likes: 100,
          shares: 25,
          positiveSentiment: 60,
          neutralSentiment: 30,
          negativeSentiment: 10
        },
        {
          id: 2,
          source: 'instagram',
          views: 200,
          posts: 34,
          engagements: 123,
          comments: 40,
          likes: 80,
          shares: 30,
          positiveSentiment: 70,
          neutralSentiment: 20,
          negativeSentiment: 10
        }
      ],
      competitorMediaTable: [
        {
          id: 2,
          sourceName: 'Netpress',
          sourceUrl: 'https://netpress.com.mk/me-iti-formirame-nov-urgenten-internistichki-centar-za-1-godina-napravivme-pozitivni-promeni-vo-zdravstvoto/',
          title: 'Меџити: Формираме нов ургентен интернистички центар, за 1 година направивме позитивни промени во здравството',
          type: 'media',
          date: '2021-10-02',
          engagements: 100,
          likes: 50,
          comments: 20,
          shares: 10,
          keywords: ['Mastercard', 'Paypal'],
        },
        {
          id: 4,
          sourceName: 'Faktor',
          sourceUrl: 'https://faktor.mk/alkaloid-i-komercijalna-banka-doniraat-povekje-od-400000-evra-za-formiranje-na-urgenten-trijazen-centar-na-klinikata-za-toksikologija',
          title: '„Алкалоид“ и „Комерцијална банка“ донираат повеќе од 400.000 евра за формирање на ургентен тријажен центар на клиниката за токсикологија',
          type: 'media',
          engagements: 300,
          likes: 150,
          comments: 75,
          shares: 37,
          date: '2021-10-02',
        },
        {
          id: 5,
          sourceName: 'Faktor',
          sourceUrl: 'https://faktor.mk/koj-plati-270-iljadi-evra-za-1000-akcii-na-komercijalna---nova-blok-transakcija-so-najgolemata-banka-vo-zemjava',
          title: 'КОЈ ПЛАТИ 270 ИЛЈАДИ ЕВРА ЗА 1.000 АКЦИИ НА КОМЕРЦИЈАЛНА - нова блок трансакција со најголемата банка во земјава',
          type: 'media',
          engagements: 262,
          likes: 150,
          comments: 75,
          shares: 37,
          date: '2021-10-02',
        },
        {
          id: 6,
          sourceName: 'Makfax',
          sourceUrl: 'https://makfax.com.mk/makedonija/%d0%bc%d0%b5%d1%9f%d0%b8%d1%82%d0%b8-%d1%84%d0%be%d1%80%d0%bc%d0%b8%d1%80%d0%b0%d0%bc%d0%b5-%d0%bd%d0%be%d0%b2-%d1%83%d1%80%d0%b3%d0%b5%d0%bd%d1%82%d0%b5%d0%bd-%d0%b8%d0%bd%d1%82%d0%b5%d1%80%d0%bd/',
          title: 'Меџити: Формираме нов ургентен интернистички центар, за една година направивме позитивни промени во здравството',
          type: 'media',
          engagements: 204,
          likes: 150,
          comments: 40,
          shares: 14,
          date: '2024-02-06',
          // 16:57
        },
        {
          id: 7,
          sourceName: 'Kanal5',
          sourceUrl: 'https://kanal5.com.mk/medjiti-potpisha-dogovor-so-komercijalna-banka-i-alkaloid-za-nov-urgenten-internistichki-centar/a624585',
          title: 'Меџити потпиша договор со Комерцијална банка и Алкалоид за нов Ургентен интернистички центар',
          type: 'media',
          engagements: 142,
          likes: 80,
          comments: 25,
          shares: 37,
          date: '2024-02-06',
        },
        {
          id: 8,
          sourceName: 'Kanal5',
          sourceUrl: 'https://kanal5.com.mk/potpishan-dogovor-za-nov-urgenten-internistichki-centar-na-toksikologija/a624509',
          title: 'Потпишан договор за нов ургентен интернистички центар на Токсикологија',
          type: 'media',
          engagements: 369,
          likes: 312,
          comments: 45,
          shares: 12,
          date: '2024-02-06',
        },
        {
          id: 9,
          sourceName: 'Press24',
          sourceUrl: 'https://press24.mk/nov-urgenten-internistichki-centar-na-klinikata-za-toksikologija',
          title: 'Нов ургентен интернистички центар на Клиниката за Токсикологија',
          type: 'media',
          engagements: 1756,
          likes: 1314,
          comments: 405,
          shares: 37,
          date: '2024-02-06',
        },
        {
          id: 10,
          sourceName: 'Fokus',
          sourceUrl: 'https://fokus.mk/alkaloid-i-komertsijalna-banka-doniraat-nad-460-000-evra-za-formirane-na-urgenten-trijazhen-tsentar-na-klinika-za-toksikologija/',
          title: '„Алкалоид“ и „Комерцијална банка“ донираат над 460.000 евра за формирање на ургентен тријажен центар на Клиника за токсикологија',
          type: 'media',
          engagements: 300,
          likes: 150,
          comments: 75,
          shares: 37,
          date: '2024-02-06',
        },
        {
          id: 11,
          sourceName: 'Opserver',
          sourceUrl: 'https://opserver.mk/makedonija/medzhiti-formirame-nov-urgenten-internistichki-centar-za-edna-godina-napravivme-pozitivni-promeni-vo-zdravstvoto/',
          title: 'Меџити: Формираме нов ургентен интернистички центар, за една година направивме позитивни промени во здравството',
          type: 'media',
          engagements: 300,
          likes: 150,
          comments: 75,
          shares: 37,
          date: '2024-02-06',
        },
        {
          id: 12,
          sourceName: 'Vecer',
          sourceUrl: 'https://www.vecer.press/%d0%bd%d0%be%d0%b2-%d1%83%d1%80%d0%b3%d0%b5%d0%bd%d1%82%d0%b5%d0%bd-%d0%b8%d0%bd%d1%82%d0%b5%d1%80%d0%bd%d0%b8%d1%81%d1%82%d0%b8%d1%87%d0%ba%d0%b8-%d1%86%d0%b5%d0%bd%d1%82%d0%b0%d1%80-%d0%bd%d0%b0/',
          title: 'Нов ургентен интернистички центар на Токсикологија, парите обезбедени од донации',
          type: 'media',
          engagements: 300,
          likes: 150,
          comments: 75,
          shares: 37,
          date: '2024-02-06',
        },
        {
          id: 13,
          sourceName: '360Stepeni',
          sourceUrl: 'https://360stepeni.mk/potpishan-dogovor-za-nov-urgenten-internistichki-tsentar-na-toksikologija/',
          title: 'Потпишан договор за нов ургентен интернистички центар на Токсикологија',
          type: 'media',
          engagements: 300,
          likes: 150,
          comments: 75,
          shares: 37,
          date: '2024-02-06',
        },
        {
          id: 14,
          sourceName: 'Lokalno',
          sourceUrl: 'https://lokalno.mk/vkupno-468-673-evra-doniraa-od-komercijalna-i-alkaloid-za-formiranje-na-urgenten-trijazhen-centar-na-klinika-za-toksikologija/',
          title: 'Вкупно 468.673 евра донираа од Комерцијална и Алкалоид за формирање на ургентен тријажен центар на Клиника за токсикологија',
          type: 'media',
          engagements: 300,
          likes: 150,
          comments: 75,
          shares: 37,
          date: '2024-02-06',
        },
        {
          id: 15,
          sourceName: 'Denar',
          sourceUrl: 'https://denar.mk/300317/biznis/alkaloid-i-komercijalna-banka-doniraat-povekje-od-chetiristotini-iljadi-evra-za-formiranje-na-urgenten-trijazhen-centar-na-toksikologija',
          title: '„Алкалоид“ и „Комерцијална банка“ донираат повеќе од 400.000 евра за формирање на ургентен тријажен центар на клиниката за токсикологија',
          type: 'media',
          engagements: 300,
          likes: 150,
          comments: 75,
          shares: 37,
          date: '2024-02-06',
        },
        {
          id: 16,
          sourceName: 'Pari',
          sourceUrl: 'https://pari.com.mk/toksikologija-ke-dobie-urgenten/',
          title: 'Токсикологија ќе добие ургентен тријажен центар – Комерцијална банка и „Алкалоид“ донираат над 460 илјади евра',
          type: 'media',
          engagements: 300,
          likes: 150,
          comments: 75,
          shares: 37,
          date: '2024-02-06',
        },
        {
          id: 17,
          sourceName: 'eMagazin',
          sourceUrl: 'https://emagazin.mk/%d1%81%d0%be-%d0%b4%d0%be%d0%bd%d0%b0%d1%86%d0%b8%d1%98%d0%b0-%d0%bd%d0%b0-%d0%b0%d0%bb%d0%ba%d0%b0%d0%bb%d0%be%d0%b8%d0%b4-%d0%b8-%d0%ba%d0%be%d0%bc%d0%b5%d1%80%d1%86%d0%b8%d1%98%d0%b0%d0%bb%d0%bd/',
          title: 'Со донација на Алкалоид и Комерцијална банка ќе се формира нов ургентен интернистички центар на Универзитетската клиника за токсикологија',
          type: 'media',
          engagements: 300,
          likes: 150,
          comments: 75,
          shares: 37,
          date: '2024-02-06',
        }
      ],
      competitors: [
        "Komercijalna Banka",
        "Soceite Generale Banka",
      ]
      // newsOutletsDataCompetitors:
    },
    {
      groupName: 'Societe Generale Banka',
      dateRange: [
        this.date5DaysAgo,
        this.dateToday
      ],
      keywords: [
        'SGBKeyboard1',
        'SGBKeyboard2',
        'SGBKeyboard3',
        'SGBKeyboard4',
        'SGBKeyboard5'
      ],
      totalCompetitorArticles: {
        title: 'Articles',
        value: 240,
        percentage: -1.2,
      },
      totalCompetitorMentions: {
        title: 'Mentions',
        value: 130,
        percentage: 11.5,
      },
      totalCompetitorSocialPosts: {
        title: 'Social Posts',
        value: 110,
        percentage: 1.7,
      },
      totalArticles: {
        title: 'Articles',
        value: 300,
        percentage: 1.6,
      },
      totalMentions: {
        title: 'Mentions',
        value: 200,
        percentage: 16.5,
      },
      totalSocialPosts: {
        title: 'Social Posts',
        value: 100,
        percentage: 2.6,
      },
      totalLikes: {
        title: 'Likes',
        value: 414,
        percentage: 30.35,
      },
      totalEngagements: {
        title: 'Engagements',
        value: 521,
        percentage: 41.5,
      },
      totalComments: {
        title: 'Comments',
        value: 120,
        percentage: 11.6,
      },
      totalShares: {
        title: 'Shares',
        value: 120,
        percentage: 12.2,
      },
      newsOutletsData: {
        layout: "horizontal",
        title: 'Most active source by keyword tracker',
        sort: [{ dataKey: 'engagements', order: 'desc' }],
        series: [
          { dataKey: 'views', label: 'Engagements' },
          { dataKey: 'keywords', label: 'Keywords mentioned' },
        ],
        yAxis: [{ scaleType: 'band', dataKey: 'newsSource' }],
        data: [
          {
            newsSource: 'Sloboden Pecat',
            views: {
              dataKey: 'views',
              label: 'Engagements',
              value: 12,
            },
            keywords: {
              dataKey: 'keywords',
              label: 'Keywords',
              value: 15,
            },
            dateCollected: {
              dataKey: 'dateCollected',
              label: 'Date',
              value: '2024-10-01',
            },
          },
          {
            newsSource: 'Republika',
            views: {
              label: 'Engagements',
              value: 3,
            },
            keywords: {
              label: 'Keywords',
              value: 67,
            },
            dateCollected: {
              label: 'Date',
              value: '2024-10-01',
            },
          },
          {
            newsSource: 'Info.mk',
            views: {
              label: 'Engagements',
              value: 23,
            },
            keywords: {
              label: 'Keywords',
              value: 8,
            },
            dateCollected: {
              label: 'Date',
              value: '2024-10-01',
            },
          }
        ],
      },
      mediaOutletsData: {
        layout: "horizontal",
        title: 'Most Active Media Outlet',
        sort: [{ dataKey: 'engagements', order: 'asc' }],
        series: [
          { dataKey: 'views', label: 'Engagements' },
          { dataKey: 'keywords', label: 'Keywords mentioned' },
        ],
        yAxis: [{ scaleType: 'band', dataKey: 'mediaSource' }],
        data: [
          {
            mediaSource: 'Instagram',
            views: {
              dataKey: 'views',
              label: 'Engagements',
              value: 56,
            },
            keywords: {
              dataKey: 'keywords',
              label: 'Keywords',
              value: 4,
            },
            dateCollected: {
              dataKey: 'dateCollected',
              label: 'Date',
              value: '2024-10-01',
            },
          },
          {
            mediaSource: 'Facebook',
            views: {
              label: 'Engagements',
              value: 52,
            },
            keywords: {
              label: 'Keywords',
              value: 10,
            },
            dateCollected: {
              label: 'Date',
              value: '2024-10-01',
            },
          },
          {
            mediaSource: 'Twitter',
            views: {
              label: 'Engagements',
              value: 23,
            },
            keywords: {
              label: 'Keywords',
              value: 9,
            },
            dateCollected: {
              label: 'Date',
              value: '2024-10-01',
            },
          }
        ],
      },
      mostEngagingOutletOnFb: {
        layout: "horizontal",
        title: 'Most Active Source',
        sort: [{ dataKey: 'engagements', order: 'asc' }],
        series: [
          { dataKey: 'views', label: 'Engagements' },
          { dataKey: 'keywords', label: 'Keywords mentioned' },
        ],
        yAxis: [{ scaleType: 'band', dataKey: 'mediaSource' }],
        data: [
          {
            mediaSource: 'Pari.mk',
            views: {
              dataKey: 'views',
              label: 'Engagements',
              value: 59,
            },
            keywords: {
              dataKey: 'keywords',
              label: 'Keywords',
              value: 5,
            },
            dateCollected: {
              dataKey: 'dateCollected',
              label: 'Date',
              value: '2024-10-01',
            },
          },
          {
            mediaSource: 'on.net.mk',
            views: {
              label: 'Engagements',
              value: 57,
            },
            keywords: {
              label: 'Keywords',
              value: 3,
            },
            dateCollected: {
              label: 'Date',
              value: '2024-10-01',
            },
          },
          {
            mediaSource: 'time.mk',
            views: {
              label: 'Engagements',
              value: 86,
            },
            keywords: {
              label: 'Keywords',
              value: 8,
            },
            dateCollected: {
              label: 'Date',
              value: '2024-10-01',
            },
          }
        ],
      },
      mostActiveSourceByCompetitor: {
        layout: "horizontal",
        title: 'Most Active Source',
        sort: [{ dataKey: 'engagements', order: 'asc' }],
        series: [
          { dataKey: 'views', label: 'Engagements' },
          { dataKey: 'keywords', label: 'Keywords mentioned' },
        ],
        yAxis: [{ scaleType: 'band', dataKey: 'newsSource' }],
        data: [
          {
            newsSource: 'Tea',
            views: {
              dataKey: 'views',
              label: 'Engagements',
              value: 59,
            },
            keywords: {
              dataKey: 'keywords',
              label: 'Keywords',
              value: 5,
            },
            dateCollected: {
              dataKey: 'dateCollected',
              label: 'Date',
              value: '2024-10-01',
            },
          },
          {
            newsSource: 'Fokus',
            views: {
              dataKey: 'views',
              label: 'Engagements',
              value: 59,
            },
            keywords: {
              dataKey: 'keywords',
              label: 'Keywords',
              value: 5,
            },
            dateCollected: {
              dataKey: 'dateCollected',
              label: 'Date',
              value: '2024-10-01',
            },
          },
          {
            newsSource: 'Republika',
            views: {
              dataKey: 'views',
              label: 'Engagements',
              value: 59,
            },
            keywords: {
              dataKey: 'keywords',
              label: 'Keywords',
              value: 5,
            },
            dateCollected: {
              dataKey: 'dateCollected',
              label: 'Date',
              value: '2024-10-01',
            },
          },
          {
            newsSource: 'Sloboden Pecat',
            views: {
              dataKey: 'views',
              label: 'Engagements',
              value: 59,
            },
            keywords: {
              dataKey: 'keywords',
              label: 'Keywords',
              value: 5,
            },
            dateCollected: {
              dataKey: 'dateCollected',
              label: 'Date',
              value: '2024-10-01',
            },
          },
          {
            newsSource: 'Republika',
            views: {
              label: 'Engagements',
              value: 57,
            },
            keywords: {
              label: 'Keywords',
              value: 3,
            },
            dateCollected: {
              label: 'Date',
              value: '2024-10-01',
            },
          },
          {
            newsSource: 'Info.mk',
            views: {
              label: 'Engagements',
              value: 86,
            },
            keywords: {
              label: 'Keywords',
              value: 8,
            },
            dateCollected: {
              label: 'Date',
              value: '2024-10-01',
            },
          }
        ],
      },
      dailyPostsDistribution: {
        layout: "vertical",
        title: 'Daily Posts Distribution',
        series: [
          { dataKey: 'count', },
        ],
        xAxis: [
          { scaleType: 'band', dataKey: 'hour' }
        ],
        data: [
          { hour: '00:00', count: 10 },
          { hour: '01:00', count: 5 },
          { hour: '02:00', count: 8 },
          { hour: '03:00', count: 3 },
          { hour: '04:00', count: 7 },
          { hour: '05:00', count: 12 },
          { hour: '06:00', count: 15 },
          { hour: '07:00', count: 20 },
          { hour: '08:00', count: 25 },
          { hour: '09:00', count: 30 },
          { hour: '10:00', count: 35 },
          { hour: '11:00', count: 40 },
          { hour: '12:00', count: 45 },
          { hour: '13:00', count: 50 },
          { hour: '14:00', count: 55 },
          { hour: '15:00', count: 60 },
          { hour: '16:00', count: 55 },
          { hour: '17:00', count: 50 },
          { hour: '18:00', count: 45 },
          { hour: '19:00', count: 40 },
          { hour: '20:00', count: 35 },
          { hour: '21:00', count: 30 },
          { hour: '22:00', count: 25 },
          { hour: '23:00', count: 20 },
        ]
      },
      keywordEngagementData: [
        {
          keyword: 'Mastercard',
          engagements: [
            { date: '2024-05-01', value: 0 },
            { date: '2024-06-02', value: 30 },
            { date: '2024-07-03', value: 10 },
            { date: '2024-08-04', value: 70 },
            { date: '2024-09-05', value: 50 },
          ],
        },
        {
          keyword: 'Visa',
          engagements: [
            { date: '2024-05-01', value: 10 },
            { date: '2024-06-02', value: 0 },
            { date: '2024-07-03', value: 20 },
            { date: '2024-08-04', value: 40 },
            { date: '2024-09-05', value: 60 },
          ]
        },
        {
          keyword: 'PayPal',
          engagements: [
            { date: '2024-05-01', value: 30 },
            { date: '2024-06-02', value: 20 },
            { date: '2024-07-03', value: 0 },
            { date: '2024-08-04', value: 60 },
            { date: '2024-09-05', value: 40 },
          ]
        },
        {
          keyword: 'Stripe',
          engagements: [
            { date: '2024-05-01', value: 50 },
            { date: '2024-06-02', value: 40 },
            { date: '2024-07-03', value: 60 },
            { date: '2024-08-04', value: 0 },
            { date: '2024-09-05', value: 100 }
          ]
        },
        {
          keyword: 'Square',
          engagements: [
            { date: '2024-05-01', value: 70 },
            { date: '2024-06-02', value: 60 },
            { date: '2024-07-03', value: 40 },
            { date: '2024-08-04', value: 100 },
            { date: '2024-09-05', value: 0 },
          ]
        }
      ],
      mediaTableData: [
        {
          id: 6,
          sourceName: 'Makfax',
          sourceUrl: 'https://makfax.com.mk/makedonija/%d0%bc%d0%b5%d1%9f%d0%b8%d1%82%d0%b8-%d1%84%d0%be%d1%80%d0%bc%d0%b8%d1%80%d0%b0%d0%bc%d0%b5-%d0%bd%d0%be%d0%b2-%d1%83%d1%80%d0%b3%d0%b5%d0%bd%d1%82%d0%b5%d0%bd-%d0%b8%d0%bd%d1%82%d0%b5%d1%80%d0%bd/',
          title: 'Меџити: Формираме нов ургентен интернистички центар, за една година направивме позитивни промени во здравството',
          type: 'media',
          engagements: 204,
          likes: 150,
          comments: 40,
          shares: 14,
          date: '2024-02-06',
          // 16:57
        },
        {
          id: 4,
          sourceName: 'Faktor',
          sourceUrl: 'https://faktor.mk/alkaloid-i-komercijalna-banka-doniraat-povekje-od-400000-evra-za-formiranje-na-urgenten-trijazen-centar-na-klinikata-za-toksikologija',
          title: '„Алкалоид“ и „Комерцијална банка“ донираат повеќе од 400.000 евра за формирање на ургентен тријажен центар на клиниката за токсикологија',
          type: 'media',
          engagements: 300,
          likes: 150,
          comments: 75,
          shares: 37,
          date: '2021-10-02',
        },
        {
          id: 5,
          sourceName: 'Faktor',
          sourceUrl: 'https://faktor.mk/koj-plati-270-iljadi-evra-za-1000-akcii-na-komercijalna---nova-blok-transakcija-so-najgolemata-banka-vo-zemjava',
          title: 'КОЈ ПЛАТИ 270 ИЛЈАДИ ЕВРА ЗА 1.000 АКЦИИ НА КОМЕРЦИЈАЛНА - нова блок трансакција со најголемата банка во земјава',
          type: 'media',
          engagements: 262,
          likes: 150,
          comments: 75,
          shares: 37,
          date: '2021-10-02',
        },
        {
          id: 7,
          sourceName: 'Kanal5',
          sourceUrl: 'https://kanal5.com.mk/medjiti-potpisha-dogovor-so-komercijalna-banka-i-alkaloid-za-nov-urgenten-internistichki-centar/a624585',
          title: 'Меџити потпиша договор со Комерцијална банка и Алкалоид за нов Ургентен интернистички центар',
          type: 'media',
          engagements: 142,
          likes: 80,
          comments: 25,
          shares: 37,
          date: '2024-02-06',
        },
        {
          id: 2,
          sourceName: 'Netpress',
          sourceUrl: 'https://netpress.com.mk/me-iti-formirame-nov-urgenten-internistichki-centar-za-1-godina-napravivme-pozitivni-promeni-vo-zdravstvoto/',
          title: 'Меџити: Формираме нов ургентен интернистички центар, за 1 година направивме позитивни промени во здравството',
          type: 'media',
          date: '2021-10-02',
          engagements: 100,
          likes: 50,
          comments: 20,
          shares: 10,
          keywords: ['Mastercard', 'Paypal'],
        },
        {
          id: 9,
          sourceName: 'Press24',
          sourceUrl: 'https://press24.mk/nov-urgenten-internistichki-centar-na-klinikata-za-toksikologija',
          title: 'Нов ургентен интернистички центар на Клиниката за Токсикологија',
          type: 'media',
          engagements: 1756,
          likes: 1314,
          comments: 405,
          shares: 37,
          date: '2024-02-06',
        },
        {
          id: 8,
          sourceName: 'Kanal5',
          sourceUrl: 'https://kanal5.com.mk/potpishan-dogovor-za-nov-urgenten-internistichki-centar-na-toksikologija/a624509',
          title: 'Потпишан договор за нов ургентен интернистички центар на Токсикологија',
          type: 'media',
          engagements: 369,
          likes: 312,
          comments: 45,
          shares: 12,
          date: '2024-02-06',
        },
        {
          id: 10,
          sourceName: 'Fokus',
          sourceUrl: 'https://fokus.mk/alkaloid-i-komertsijalna-banka-doniraat-nad-460-000-evra-za-formirane-na-urgenten-trijazhen-tsentar-na-klinika-za-toksikologija/',
          title: '„Алкалоид“ и „Комерцијална банка“ донираат над 460.000 евра за формирање на ургентен тријажен центар на Клиника за токсикологија',
          type: 'media',
          engagements: 300,
          likes: 150,
          comments: 75,
          shares: 37,
          date: '2024-02-06',
        },
        {
          id: 11,
          sourceName: 'Opserver',
          sourceUrl: 'https://opserver.mk/makedonija/medzhiti-formirame-nov-urgenten-internistichki-centar-za-edna-godina-napravivme-pozitivni-promeni-vo-zdravstvoto/',
          title: 'Меџити: Формираме нов ургентен интернистички центар, за една година направивме позитивни промени во здравството',
          type: 'media',
          engagements: 300,
          likes: 150,
          comments: 75,
          shares: 37,
          date: '2024-02-06',
        },
        {
          id: 12,
          sourceName: 'Vecer',
          sourceUrl: 'https://www.vecer.press/%d0%bd%d0%be%d0%b2-%d1%83%d1%80%d0%b3%d0%b5%d0%bd%d1%82%d0%b5%d0%bd-%d0%b8%d0%bd%d1%82%d0%b5%d1%80%d0%bd%d0%b8%d1%81%d1%82%d0%b8%d1%87%d0%ba%d0%b8-%d1%86%d0%b5%d0%bd%d1%82%d0%b0%d1%80-%d0%bd%d0%b0/',
          title: 'Нов ургентен интернистички центар на Токсикологија, парите обезбедени од донации',
          type: 'media',
          engagements: 300,
          likes: 150,
          comments: 75,
          shares: 37,
          date: '2024-02-06',
        },
        {
          id: 13,
          sourceName: '360Stepeni',
          sourceUrl: 'https://360stepeni.mk/potpishan-dogovor-za-nov-urgenten-internistichki-tsentar-na-toksikologija/',
          title: 'Потпишан договор за нов ургентен интернистички центар на Токсикологија',
          type: 'media',
          engagements: 300,
          likes: 150,
          comments: 75,
          shares: 37,
          date: '2024-02-06',
        },
        {
          id: 14,
          sourceName: 'Lokalno',
          sourceUrl: 'https://lokalno.mk/vkupno-468-673-evra-doniraa-od-komercijalna-i-alkaloid-za-formiranje-na-urgenten-trijazhen-centar-na-klinika-za-toksikologija/',
          title: 'Вкупно 468.673 евра донираа од Комерцијална и Алкалоид за формирање на ургентен тријажен центар на Клиника за токсикологија',
          type: 'media',
          engagements: 300,
          likes: 150,
          comments: 75,
          shares: 37,
          date: '2024-02-06',
        },
        {
          id: 15,
          sourceName: 'Denar',
          sourceUrl: 'https://denar.mk/300317/biznis/alkaloid-i-komercijalna-banka-doniraat-povekje-od-chetiristotini-iljadi-evra-za-formiranje-na-urgenten-trijazhen-centar-na-toksikologija',
          title: '„Алкалоид“ и „Комерцијална банка“ донираат повеќе од 400.000 евра за формирање на ургентен тријажен центар на клиниката за токсикологија',
          type: 'media',
          engagements: 300,
          likes: 150,
          comments: 75,
          shares: 37,
          date: '2024-02-06',
        },
        {
          id: 16,
          sourceName: 'Pari',
          sourceUrl: 'https://pari.com.mk/toksikologija-ke-dobie-urgenten/',
          title: 'Токсикологија ќе добие ургентен тријажен центар – Комерцијална банка и „Алкалоид“ донираат над 460 илјади евра',
          type: 'media',
          engagements: 300,
          likes: 150,
          comments: 75,
          shares: 37,
          date: '2024-02-06',
        },
        {
          id: 17,
          sourceName: 'eMagazin',
          sourceUrl: 'https://emagazin.mk/%d1%81%d0%be-%d0%b4%d0%be%d0%bd%d0%b0%d1%86%d0%b8%d1%98%d0%b0-%d0%bd%d0%b0-%d0%b0%d0%bb%d0%ba%d0%b0%d0%bb%d0%be%d0%b8%d0%b4-%d0%b8-%d0%ba%d0%be%d0%bc%d0%b5%d1%80%d1%86%d0%b8%d1%98%d0%b0%d0%bb%d0%bd/',
          title: 'Со донација на Алкалоид и Комерцијална банка ќе се формира нов ургентен интернистички центар на Универзитетската клиника за токсикологија',
          type: 'media',
          engagements: 300,
          likes: 150,
          comments: 75,
          shares: 37,
          date: '2024-02-06',
        }
      ],
      sentiment: [
        {
          label: 'Positive',
          value: 70
        },
        {
          label: 'Neutral',
          value: 20
        },
        {
          label: 'Negative',
          value: 10
        }
      ],
      sentimentScore: 66,
      shareOfVoice: [
        {
          id: 1,
          label: 'Komercijalna Banka',
          value: 30,
          abbreviation: 'KB'
        },
        {
          id: 2,
          label: 'NLB Banka',
          value: 40,
          abbreviation: 'NLB'
        },
        {
          id: 3,
          label: 'Societe Generale Banka',
          value: 30,
          abbreviation: 'SGB'
        }
      ],
      competitorSummary: [
        {
          id: 1,
          source: 'facebook',
          views: 100,
          posts: 59,
          engagements: 200,
          comments: 50,
          likes: 100,
          shares: 25,
          positiveSentiment: 60,
          neutralSentiment: 30,
          negativeSentiment: 10
        },
        {
          id: 2,
          source: 'instagram',
          views: 200,
          posts: 34,
          engagements: 123,
          comments: 40,
          likes: 80,
          shares: 30,
          positiveSentiment: 70,
          neutralSentiment: 20,
          negativeSentiment: 10
        }
      ],
      competitorMediaTable: [
        {
          id: 2,
          sourceName: 'Netpress',
          sourceUrl: 'https://netpress.com.mk/me-iti-formirame-nov-urgenten-internistichki-centar-za-1-godina-napravivme-pozitivni-promeni-vo-zdravstvoto/',
          title: 'Меџити: Формираме нов ургентен интернистички центар, за 1 година направивме позитивни промени во здравството',
          type: 'media',
          date: '2021-10-02',
          engagements: 100,
          likes: 50,
          comments: 20,
          shares: 10,
          keywords: ['Mastercard', 'Paypal'],
        },
        {
          id: 4,
          sourceName: 'Faktor',
          sourceUrl: 'https://faktor.mk/alkaloid-i-komercijalna-banka-doniraat-povekje-od-400000-evra-za-formiranje-na-urgenten-trijazen-centar-na-klinikata-za-toksikologija',
          title: '„Алкалоид“ и „Комерцијална банка“ донираат повеќе од 400.000 евра за формирање на ургентен тријажен центар на клиниката за токсикологија',
          type: 'media',
          engagements: 300,
          likes: 150,
          comments: 75,
          shares: 37,
          date: '2021-10-02',
        },
        {
          id: 5,
          sourceName: 'Faktor',
          sourceUrl: 'https://faktor.mk/koj-plati-270-iljadi-evra-za-1000-akcii-na-komercijalna---nova-blok-transakcija-so-najgolemata-banka-vo-zemjava',
          title: 'КОЈ ПЛАТИ 270 ИЛЈАДИ ЕВРА ЗА 1.000 АКЦИИ НА КОМЕРЦИЈАЛНА - нова блок трансакција со најголемата банка во земјава',
          type: 'media',
          engagements: 262,
          likes: 150,
          comments: 75,
          shares: 37,
          date: '2021-10-02',
        },
        {
          id: 6,
          sourceName: 'Makfax',
          sourceUrl: 'https://makfax.com.mk/makedonija/%d0%bc%d0%b5%d1%9f%d0%b8%d1%82%d0%b8-%d1%84%d0%be%d1%80%d0%bc%d0%b8%d1%80%d0%b0%d0%bc%d0%b5-%d0%bd%d0%be%d0%b2-%d1%83%d1%80%d0%b3%d0%b5%d0%bd%d1%82%d0%b5%d0%bd-%d0%b8%d0%bd%d1%82%d0%b5%d1%80%d0%bd/',
          title: 'Меџити: Формираме нов ургентен интернистички центар, за една година направивме позитивни промени во здравството',
          type: 'media',
          engagements: 204,
          likes: 150,
          comments: 40,
          shares: 14,
          date: '2024-02-06',
          // 16:57
        },
        {
          id: 7,
          sourceName: 'Kanal5',
          sourceUrl: 'https://kanal5.com.mk/medjiti-potpisha-dogovor-so-komercijalna-banka-i-alkaloid-za-nov-urgenten-internistichki-centar/a624585',
          title: 'Меџити потпиша договор со Комерцијална банка и Алкалоид за нов Ургентен интернистички центар',
          type: 'media',
          engagements: 142,
          likes: 80,
          comments: 25,
          shares: 37,
          date: '2024-02-06',
        },
        {
          id: 8,
          sourceName: 'Kanal5',
          sourceUrl: 'https://kanal5.com.mk/potpishan-dogovor-za-nov-urgenten-internistichki-centar-na-toksikologija/a624509',
          title: 'Потпишан договор за нов ургентен интернистички центар на Токсикологија',
          type: 'media',
          engagements: 369,
          likes: 312,
          comments: 45,
          shares: 12,
          date: '2024-02-06',
        },
        {
          id: 9,
          sourceName: 'Press24',
          sourceUrl: 'https://press24.mk/nov-urgenten-internistichki-centar-na-klinikata-za-toksikologija',
          title: 'Нов ургентен интернистички центар на Клиниката за Токсикологија',
          type: 'media',
          engagements: 1756,
          likes: 1314,
          comments: 405,
          shares: 37,
          date: '2024-02-06',
        },
        {
          id: 10,
          sourceName: 'Fokus',
          sourceUrl: 'https://fokus.mk/alkaloid-i-komertsijalna-banka-doniraat-nad-460-000-evra-za-formirane-na-urgenten-trijazhen-tsentar-na-klinika-za-toksikologija/',
          title: '„Алкалоид“ и „Комерцијална банка“ донираат над 460.000 евра за формирање на ургентен тријажен центар на Клиника за токсикологија',
          type: 'media',
          engagements: 300,
          likes: 150,
          comments: 75,
          shares: 37,
          date: '2024-02-06',
        },
        {
          id: 11,
          sourceName: 'Opserver',
          sourceUrl: 'https://opserver.mk/makedonija/medzhiti-formirame-nov-urgenten-internistichki-centar-za-edna-godina-napravivme-pozitivni-promeni-vo-zdravstvoto/',
          title: 'Меџити: Формираме нов ургентен интернистички центар, за една година направивме позитивни промени во здравството',
          type: 'media',
          engagements: 300,
          likes: 150,
          comments: 75,
          shares: 37,
          date: '2024-02-06',
        },
        {
          id: 12,
          sourceName: 'Vecer',
          sourceUrl: 'https://www.vecer.press/%d0%bd%d0%be%d0%b2-%d1%83%d1%80%d0%b3%d0%b5%d0%bd%d1%82%d0%b5%d0%bd-%d0%b8%d0%bd%d1%82%d0%b5%d1%80%d0%bd%d0%b8%d1%81%d1%82%d0%b8%d1%87%d0%ba%d0%b8-%d1%86%d0%b5%d0%bd%d1%82%d0%b0%d1%80-%d0%bd%d0%b0/',
          title: 'Нов ургентен интернистички центар на Токсикологија, парите обезбедени од донации',
          type: 'media',
          engagements: 300,
          likes: 150,
          comments: 75,
          shares: 37,
          date: '2024-02-06',
        },
        {
          id: 13,
          sourceName: '360Stepeni',
          sourceUrl: 'https://360stepeni.mk/potpishan-dogovor-za-nov-urgenten-internistichki-tsentar-na-toksikologija/',
          title: 'Потпишан договор за нов ургентен интернистички центар на Токсикологија',
          type: 'media',
          engagements: 300,
          likes: 150,
          comments: 75,
          shares: 37,
          date: '2024-02-06',
        },
        {
          id: 14,
          sourceName: 'Lokalno',
          sourceUrl: 'https://lokalno.mk/vkupno-468-673-evra-doniraa-od-komercijalna-i-alkaloid-za-formiranje-na-urgenten-trijazhen-centar-na-klinika-za-toksikologija/',
          title: 'Вкупно 468.673 евра донираа од Комерцијална и Алкалоид за формирање на ургентен тријажен центар на Клиника за токсикологија',
          type: 'media',
          engagements: 300,
          likes: 150,
          comments: 75,
          shares: 37,
          date: '2024-02-06',
        },
        {
          id: 15,
          sourceName: 'Denar',
          sourceUrl: 'https://denar.mk/300317/biznis/alkaloid-i-komercijalna-banka-doniraat-povekje-od-chetiristotini-iljadi-evra-za-formiranje-na-urgenten-trijazhen-centar-na-toksikologija',
          title: '„Алкалоид“ и „Комерцијална банка“ донираат повеќе од 400.000 евра за формирање на ургентен тријажен центар на клиниката за токсикологија',
          type: 'media',
          engagements: 300,
          likes: 150,
          comments: 75,
          shares: 37,
          date: '2024-02-06',
        },
        {
          id: 16,
          sourceName: 'Pari',
          sourceUrl: 'https://pari.com.mk/toksikologija-ke-dobie-urgenten/',
          title: 'Токсикологија ќе добие ургентен тријажен центар – Комерцијална банка и „Алкалоид“ донираат над 460 илјади евра',
          type: 'media',
          engagements: 300,
          likes: 150,
          comments: 75,
          shares: 37,
          date: '2024-02-06',
        },
        {
          id: 17,
          sourceName: 'eMagazin',
          sourceUrl: 'https://emagazin.mk/%d1%81%d0%be-%d0%b4%d0%be%d0%bd%d0%b0%d1%86%d0%b8%d1%98%d0%b0-%d0%bd%d0%b0-%d0%b0%d0%bb%d0%ba%d0%b0%d0%bb%d0%be%d0%b8%d0%b4-%d0%b8-%d0%ba%d0%be%d0%bc%d0%b5%d1%80%d1%86%d0%b8%d1%98%d0%b0%d0%bb%d0%bd/',
          title: 'Со донација на Алкалоид и Комерцијална банка ќе се формира нов ургентен интернистички центар на Универзитетската клиника за токсикологија',
          type: 'media',
          engagements: 300,
          likes: 150,
          comments: 75,
          shares: 37,
          date: '2024-02-06',
        }
      ],
      competitors: [
        'Komercijalna Banka',
        'NLB Banka',
      ]
    },
  ]

  selectedKeywordGroup = this.keywordGroups[0];
  selectedSentiment = this.selectedKeywordGroup.sentiment;

  currentUser = {
    id: 1,
    username: 'password',
    first_name: 'Boban',
    last_name: 'Prezimevski',
    password: 'username',
    email: 'guardsmanBob@mail4life.com',
    token: false
  }

  selectedCompetitor = this.selectedKeywordGroup.competitors[0]; // default selected competitor

  setSelectedCompetitor(competitor) {
    this.selectedCompetitor = this.selectedKeywordGroup.competitors[competitor]
  }

  setCurrentUser() { // ova nema da ostane vaka, ova e samo zasega
    this.currentUser.token = true;
  }

  getCurrentKeywords() {
    return this.selectedKeywordGroup.keywords
  }

  getCurrentKeywordGroup() {
    return this.selectedKeywordGroup;
  }

  getNewsOutletData() {
    return this.newsOutletsData.filter(newsOutlet => newsOutlet.keywordGroup === this.selectedKeywordGroup)
  }
  
  setSelectedKeywordGroup(keywordGroup) {
    const index = this.keywordGroups.findIndex(group => group.groupName === keywordGroup)
    this.selectedKeywordGroup = this.keywordGroups[index]
  }

  async login(data) {
    try {
      // const response = await httpService.post('/auth/jwt', data)
      // console.log("rootStore: login: ", response);
      // const { groups, access_token } = response.data

      // const response = axios.post('https//devvinci.com/auth/jwt', data)
      // const { groups, access_token } = response.data

      // this.currentUser.token = access_token;

      const response = await fetch('https://api.devvinci.com/auth/jwt', {
        method: 'POST',
        body: JSON.stringify(data),
        // headers: {
        //   'Content-Type': 'application/json',
        //   'Access-Control-Allow-Origin': '*'
        // }
      });
      const { groups, access_token } = await response.json();
      this.currentUser.token = access_token;

    } catch(error) {
      console.log("Error auth/jwt: ", error);
    }
  }


  constructor() {
    console.log('RootStore constructed: ', this.selectedKeywordGroup)
    console.log("ROOT STORE USER: ", this.currentUser)

    makeAutoObservable(this)
  }
}

const rootStore = new RootStore()
export default rootStore;
