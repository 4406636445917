import * as React from 'react';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import { trackerSummaryTable } from '../../store/dummyData';
import { Divider, Paper, Typography } from '@mui/material';
import { 
  ConnectWithoutContactOutlined, 
  PostAddOutlined, 
  FavoriteBorderOutlined, 
  ShareOutlined, 
  CommentOutlined, 
  RemoveRedEyeOutlined 
} from '@mui/icons-material';

export default function SummaryTable() {
  console.log('trackerSummaryTable', trackerSummaryTable)

  const style = {
    display: 'flex',
    justifyContent: 'center',
  }

  return (
    <Paper sx={{
      height: '100%'
    }}>
      <Box sx={{ padding: 1.5 }}>
        <Typography variant="h4">
          Summary Table
        </Typography>
      </Box>
      <Divider />
      <DataGrid
        sx={{
          '.MuiDataGrid-row': {
            '&:nth-of-type(even)': {
              backgroundColor: 'rgba(244,244,244, .5)',
            },
          },
        }}
        rows={trackerSummaryTable.rows}
        // density='compact'
        columns={[
          { field: 'source', headerName: 'Source', sortable: false, flex: 1 },
          { field: 'engagements', headerName: <div title='engagements' style={style}><ConnectWithoutContactOutlined /></div>, sortable: false, flex: 1 },
          { field: 'posts', headerName: <div title='posts' style={style}><PostAddOutlined /></div>, sortable: false, flex: 1 },
          { field: 'likes', headerName: <div title='likes' style={style}><FavoriteBorderOutlined /></div>, sortable: false, flex: 1 },
          { field: 'shares', headerName: <div title='shares' style={style}><ShareOutlined /></div>, sortable: false, flex: 1 },
          { field: 'comments', headerName: <div title='comments' style={style}><CommentOutlined /></div>, sortable: false, flex: 1 },
          { field: 'views', headerName: <div title='views' style={style}><RemoveRedEyeOutlined /></div>, sortable: false, flex: 1 },
        ]}
        disableColumnMenu
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
        pageSizeOptions={[5, 10]}
        autoHeight
        disableRowSelectionOnClick
      />
    </Paper>
  );
}
