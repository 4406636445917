import { Box, Container, Grid, Paper, Typography } from '@mui/material';
import React from 'react';
import DropSelect from '../../components/DropSelect/DropSelect';
import DateRangePicker from '../../components/DateRangePicker/DateRangePicker';
import Keywords from '../../components/Keywords/Keywords';
import rootStore from '../../store/rootStore';
import CompetitorSummary from '../../components/CompetitorSummary/CompetitorSummary';
import ShareOfVoice from '../../components/ShareOfVoice/ShareOfVoice';
import DonutChart from '../../components/DonutChart/DonutChart';
import HorizontalBars from '../../components/HorizontalBars/HorizontalBars';
import { observer } from "mobx-react";
import MediaTable from '../../components/MediaTable/MediaTable';


const Competitors = () => {
  return (
    <Container sx={{
      paddingTop: 2,
      display: 'grid',
      gridTemplateColumns: '1fr 1fr 1fr 1fr',
      gridTemplateRows: 'auto auto auto auto auto auto auto',
      gap: 1,
      gridTemplateAreas: `
        "keywordGroup keywordGroup keywordGroup keywords"
        "competitorMediaTable competitorMediaTable competitorMediaTable competitorMediaTable"
        "mostActiveSourceByCompetitor mostActiveSourceByCompetitor mostActiveSourceByCompetitor mostActiveSourceByCompetitor"
        "competitorSummary competitorSummary competitorSummary competitorSummary"
        "shareOfVoice shareOfVoice sentiment sentiment"
      `,
      '@media (max-width: 1250px)': {
        gridTemplateAreas: `
          "keywordGroup keywords keywords"
          "keywordGroup keywords keywords"
          "competitorMediaTable competitorMediaTable competitorMediaTable"
          "mostActiveSourceByCompetitor mostActiveSourceByCompetitor mostActiveSourceByCompetitor"
          "competitorSummary competitorSummary competitorSummary"
          "shareOfVoice shareOfVoice sentiment"
        `,
        gridTemplateColumns: '1fr 1fr 1fr',
        gridTemplateRows: 'auto auto auto auto auto',
      },
      '@media (max-width: 900px)': {
        gridTemplateAreas: `
          "keywordGroup"
          "keywordGroup"
          "keywords"
          "competitorMediaTable"
          "mostActiveSourceByCompetitor"
          "competitorSummary"
          "sentiment"
          "shareOfVoice"
        `,
        gridTemplateColumns: '1fr',
        gridTemplateRows: 'auto auto auto auto auto auto auto auto',
      }
    }}>
      <Paper sx={{
        gridArea: 'keywordGroup',
        display: 'flex',
        flexDirection: 'column',
        p: 1.5,
        gap: 2,
        // maxHeight: '135px'
      }}>
        <Typography variant='h5'>Welcome</Typography>
        <DropSelect keywordGroups={rootStore.keywordGroups} />
        <DateRangePicker />
      </Paper>
      <Paper sx={{
          p: 1.5,
          gap: 1,
          gridArea: 'keywords',
        }}>
          <Keywords />
      </Paper>
      <Paper sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
        gridArea: 'competitorMediaTable',
      }}>
        <MediaTable rows={rootStore.selectedKeywordGroup.competitorMediaTable} showCompetitors={true} />
      </Paper>
      <Paper sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
        gridArea: 'competitorSummary',
      }}>
        <CompetitorSummary data={rootStore.selectedKeywordGroup.competitorSummary} />
      </Paper>
      <Paper sx={{  
        gridArea: 'mostActiveSourceByCompetitor',
      }}>
        <HorizontalBars showTitle data={rootStore.selectedKeywordGroup.mostActiveSourceByCompetitor} />
      </Paper>
      <Paper sx={{
        overflowX: 'hidden',
        gridArea: 'shareOfVoice'
      }}>
        <ShareOfVoice data={rootStore.selectedKeywordGroup.shareOfVoice} />
      </Paper>
      <Paper sx={{
        overflowX: 'hidden',
        gridArea: 'sentiment'
      }}>
        <DonutChart sentimentData={rootStore.selectedKeywordGroup.sentiment} sentimentScore={rootStore.selectedKeywordGroup.sentimentScore} />
      </Paper>
        
    </Container>
  );
};

export default observer(Competitors);
