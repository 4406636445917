import { Typography, Box, Paper, Divider } from '@mui/material';
import React, { useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { CommentOutlined, ConnectWithoutContactOutlined, FavoriteBorderOutlined, PostAddOutlined, RemoveRedEyeOutlined, SentimentDissatisfiedOutlined, SentimentNeutralOutlined, SentimentSatisfiedOutlined, ShareOutlined } from '@mui/icons-material';

const style = {
  display: 'flex',
  justifyContent: 'center',
}

const CompetitorSummary = ({ data }) => {
  const columns=[
    { field: 'source', headerName: 'Source', sortable: false, flex: 1 },
    { field: 'engagements', headerName: <div title='engagements' style={style}><ConnectWithoutContactOutlined /></div>, sortable: false, flex: 1 },
    { field: 'posts', headerName: <div title='posts' style={style}><PostAddOutlined /></div>, sortable: false, flex: 1 },
    { field: 'likes', headerName: <div title='likes' style={style}><FavoriteBorderOutlined /></div>, sortable: false, flex: 1 },
    { field: 'shares', headerName: <div title='shares' style={style}><ShareOutlined /></div>, sortable: false, flex: 1 },
    { field: 'comments', headerName: <div title='comments' style={style}><CommentOutlined /></div>, sortable: false, flex: 1 },
    { field: 'views', headerName: <div title='views' style={style}><RemoveRedEyeOutlined /></div>, sortable: false, flex: 1 },
    { field: 'positiveSentiment', headerName: <div title='positive sentiment' style={style}><SentimentSatisfiedOutlined /></div>, sortable: false, flex: 1 },
    { field: 'neutralSentiment', headerName: <div title='neutral sentiment' style={style}><SentimentNeutralOutlined /></div>, sortable: false, flex: 1 },
    { field: 'negativeSentiment', headerName: <div title='negative sentiment' style={style}><SentimentDissatisfiedOutlined /></div>, sortable: false, flex: 1 },
  ]

  return (
    <Box>
      <Typography p={2} variant="h4">
        Competitor Summary
      </Typography>
      <Divider />
      <div style={{ height: 400, width: '100%' }}>
        <DataGrid rows={data} columns={columns} disableColumnMenu sx={{
          '.MuiDataGrid-row': {
            '&:nth-of-type(even)': {
              backgroundColor: 'rgba(244,244,244, .5)',
            },
          },
        }} />
      </div>
    </Box>
  );
};

export default CompetitorSummary;