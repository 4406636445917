import './App.css';
import { Routes, Route, redirect, Navigate } from 'react-router-dom';
import Login from './pages/Login/LoginPage';
import Register from './pages/Register/Register';
import KeywordTracker from './pages/KeywordTracker/KeywordTracker';
import { ThemeProvider } from '@mui/material/styles';
import theme from './constants/theme';
import StyleGuide from './pages/StyleGuide/StyleGuide';
import { Box, Container } from '@mui/material';
import HeaderBar from './components/HeaderBar/HeaderBar';
import Welcome from './pages/Welcome/Welcome';
import Competitors from './pages/Competitors/Competitors';
import Analytics from './pages/Analytics/Analytics';
import rootStore from './store/rootStore';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Reports from './pages/Reports/Reports';

function App() {
  const navigate = useNavigate();
  const location = useLocation();
  console.log("token: ", rootStore.currentUser.token, location.pathname);
  const [currentLocation, setCurrentLocation] = useState(location.pathname);

  useEffect(() => {
    console.log("token: ", rootStore.currentUser.token, location.pathname);
    setCurrentLocation(location.pathname);
    if (rootStore.currentUser.token === false 
      && (currentLocation !== '/login' || currentLocation !== '/register')) {
      navigate("/login");
      return
    }
    navigate('/welcome');
    return;
  }, [rootStore.currentUser.token]);

  if(!rootStore.currentUser.token) {
    console.log('No token found, redirecting to login page');

    return (
      <ThemeProvider theme={theme}>
        <Container sx={{
          marginTop: '64px',
        }}>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
          </Routes>
        </Container>
      </ThemeProvider>
    );
  } else {
      return (
        <ThemeProvider theme={theme}>
          <Box sx={{
            marginTop: '64px',
          }}>
            {
              window.location.href.includes('login') || window.location.href.includes('register')
              ? null : <HeaderBar />
            }
            <Container 
              maxWidth={false} 
              sx={{
                paddingY: 1,
                marginLeft: rootStore.isDrawerOpen ? '250px' : 'auto',
                maxWidth: rootStore.isDrawerOpen ? 'calc(100% - 250px)' : '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                transition: '0.2s ease-in-out',
                // media
                '@media (max-width: 600px)': {
                  marginLeft: 'auto', // Set marginLeft to 'auto' for mobile screens
                  width: '100%',
                  alignItems: 'stretch',
                },
              }}
            >
              <Routes>
                <Route path="/" element={<Welcome />} />
                <Route path="/welcome" element={<Welcome />} />
                <Route path="/keyword-tracker" element={<KeywordTracker isNavOpen={rootStore.isDrawerOpen} />} />
                <Route path="/competitors" element={<Competitors />} />
                <Route path="/analytics" element={<Analytics />} />
                <Route path="/style-guide" element={<StyleGuide />} />
                <Route path="/reports" element={<Reports />} />
              </Routes>
            </Container>
          </Box>
        </ThemeProvider>
      );
  }
}

export default observer(App);
