import * as React from 'react';
import { PieChart, pieArcLabelClasses } from '@mui/x-charts/PieChart';
import { useTheme } from '@mui/material/styles';
import { Box, Container, Divider, Grid, Typography, useMediaQuery } from '@mui/material';
import { AutoMode } from '@mui/icons-material';

export default function ShareOfVoice({ data }) {

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));

  const legendPosition = matches ? { vertical: 'bottom', horizontal: 'left' } : { vertical: 'middle', horizontal: 'right' };
  const chartHeight = matches ? 350 : 250;
  const chartCenterY = matches ? '35%' : '50%';
  const chartWidth = matches ? 300 : 450;
  const chartCenterX = matches ? '75%' : '40%';

  return (
    <Box>
      <Box>
        <Typography p={2} variant="h4">
          Share of Voice
        </Typography>
        <Divider />
      </Box>
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%'
      }}>
        <PieChart
            sx={{
              [`& .${pieArcLabelClasses.root}`]: {
                fill: 'white',
                color: 'red',
                fontWeight: 'bold',
              },
            }}
            series={[
              {
                arcLabel: (item) => `${item.abbreviation} (${item.value})`,
                data,
                outerRadius: 100,
                paddingAngle: 0,
                cornerRadius: 0,
                startAngle: 0,
                endAngle: 360,
                cx: chartCenterX,
                cy: chartCenterY,
              },
            ]}
            colors={[
              theme.customColors.barleyCorn,
              theme.customColors.rajah,
              theme.customColors.newYorkPink,
              theme.customColors.dustStorm,
              theme.customColors.mountbattenPink,
            ]}
            slotProps={{
              legend: {
                direction: 'column',
                position: legendPosition,
                padding: 15,
                labelStyle: { fontSize: theme.typography.fontSize }
              },
            }}
            p={6}
            height={chartHeight}
            width={chartWidth}
          />
      </Box>
    </Box>
  );
}