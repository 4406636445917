import { Box, Container, Divider, Grid, Paper, Typography } from '@mui/material';
import React from 'react';
import DropSelect from '../../components/DropSelect/DropSelect';
import DateRangePicker from '../../components/DateRangePicker/DateRangePicker';
import Keywords from '../../components/Keywords/Keywords';
import rootStore from '../../store/rootStore';
import { observer } from 'mobx-react';
import SummaryCard from '../../components/SummaryCard/SummaryCard';
import MediaTable from '../../components/MediaTable/MediaTable';
import HorizontalBars from '../../components/HorizontalBars/HorizontalBars';
import DonutChart from '../../components/DonutChart/DonutChart';
import VerticalBars from '../../components/VerticalBars/VerticalBars';
import theme from '../../constants/theme';

const Analytics = () => {
  return (
    <Container sx={{
      paddingTop: 2,
      display: 'grid',
      gridTemplateColumns: '1fr 1fr 2fr',
      gridTemplateAreas: `
        "dateRangePicker dateRangePicker summary"
        "mediaTable mediaTable mediaTable"
        "horizontalBars horizontalBars donutChart"
        "verticalBars verticalBars verticalBars"
        "mostEngagingOutletOnFb mostEngagingOutletOnFb mostEngagingOutletOnFb"
      `,
      gridTemplateRows: 'auto auto auto auto auto auto',
      gap: 1,
      '@media (max-width: 600px)': {
        gridTemplateColumns: '1fr',
        gridTemplateAreas: `
          "dateRangePicker"
          "summary"
          "mediaTable"
          "donutChart"
          "horizontalBars"
          "verticalBars"
          "mostEngagingOutletOnFb"
        `,
        gridTemplateRows: 'auto auto auto auto auto auto auto auto',
      },
    }}>
      <Paper sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          p: 1.5,
          gap: 1,
          gridArea: 'dateRangePicker',
        }}>
        <Typography variant='h5'>Pick Dates: </Typography>
        <DateRangePicker column={true} />
      </Paper>
      <Box sx={{
        gridArea: 'mediaTable',
      }}>
        <MediaTable defaultRows={10} rows={rootStore.selectedKeywordGroup.mediaTableData} showKeywords={false} />
      </Box>
      <Paper sx={{
          gridArea: 'summary',
        }}>
          <Typography variant='h5' sx={{
            borderRadius: '10px',
            padding: 1,
            paddingLeft: 2
          }}>Summary</Typography>
          <Divider />
          <Container sx={{
            flex: 1,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            p: 1.5,
            gap: 1,
          }}>
            <SummaryCard 
              title={rootStore.selectedKeywordGroup.totalArticles.title} 
              number={rootStore.selectedKeywordGroup.totalArticles.value} 
              percentage={rootStore.selectedKeywordGroup.totalArticles.percentage}
              backgroundColor={theme.customColors.dustStorm}
            />
            <SummaryCard 
              title={rootStore.selectedKeywordGroup.totalMentions.title} 
              number={rootStore.selectedKeywordGroup.totalMentions.value} 
              percentage={rootStore.selectedKeywordGroup.totalMentions.percentage}
              backgroundColor={theme.customColors.dustStorm}
            />
            <SummaryCard 
              title={rootStore.selectedKeywordGroup.totalSocialPosts.title} 
              number={rootStore.selectedKeywordGroup.totalSocialPosts.value} 
              percentage={rootStore.selectedKeywordGroup.totalSocialPosts.percentage}
              backgroundColor={theme.customColors.dustStorm}
            />
          </Container>
      </Paper>
      <Paper sx={{ gridArea: 'donutChart' }}>
        <DonutChart sentimentData={rootStore.selectedKeywordGroup.sentiment} sentimentScore={rootStore.selectedKeywordGroup.sentimentScore} />
      </Paper>
      <Paper sx={{ gridArea: 'horizontalBars' }}>
        <HorizontalBars showTitle data={rootStore.selectedKeywordGroup.newsOutletsData} />
      </Paper>
      <Box sx={{ gridArea: 'verticalBars' }}>
        <VerticalBars showTitle={true} data={rootStore.selectedKeywordGroup.dailyPostsDistribution} />
      </Box>
      <Paper item sx={{
        gridArea: 'mostEngagingOutletOnFb'
      }}>
        <HorizontalBars showTitle data={rootStore.selectedKeywordGroup.mostEngagingOutletOnFb} />
      </Paper>
    </Container>
  );
};

export default observer(Analytics);
