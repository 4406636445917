import { Box, Divider, Typography, useMediaQuery } from '@mui/material';
import { PieChart } from '@mui/x-charts/PieChart';

import { useTheme } from '@mui/material/styles';
import { useEffect } from 'react';

function DonutChart({
  sentimentData, sentimentScore
}) {
  
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));

  const legendPosition = matches ? { vertical: 'bottom', horizontal: 'left' } : { vertical: 'middle', horizontal: 'right' };
  const chartHeight = matches ? 350 : 250;
  const chartCenterY = matches ? '35%' : '50%';
  const chartWidth = matches ? 300 : 450;
  const chartCenterX = matches ? '75%' : '40%';

  console.log('sentimentData: ', sentimentData);


  useEffect(() => {
    console.log('sentimentData: ', sentimentData);
  }, [sentimentData]);

 return (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%'
    }}
  >
    <Box sx={{
      width: '100%',
    }}>
      <Typography p={2} variant="h4">
        Sentiment Analysis
      </Typography>
      <Divider />
    </Box>
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
    }}>
      <PieChart
        colors={[
          theme.customColors.barleyCorn,
          theme.customColors.rajah,
          theme.customColors.newYorkPink,
        ]}
        slotProps={{
          legend: {
            direction: 'column',
            position: legendPosition,
            padding: 15,
            labelStyle: { fontSize: theme.typography.fontSize }
          },
        }}
        p={6}
        height={chartHeight}
        width={chartWidth}
        series={[
          {
            data: [
              { label: `${sentimentData[0].label} (${sentimentData[0].value}%)`, value: sentimentData[0].value },
              { label: `${sentimentData[1].label} (${sentimentData[1].value}%)`, value: sentimentData[1].value },
              { label: `${sentimentData[2].label} (${sentimentData[2].value}%)`, value: sentimentData[2].value }
            ],
            innerRadius: 50,
            outerRadius: 100,
            paddingAngle: 0,
            cornerRadius: 0,
            startAngle: 0,
            endAngle: 360,
            cx: chartCenterX,
            cy: chartCenterY,
          }
        ]}
      />
      {/* <Typography p={2} variant="h6">
        Sentiment Score: {sentimentScore}
      </Typography> */}
    </Box>
  </Box>
 )
}

export default DonutChart;
