import React, { useState } from 'react';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { Box, Grid, TextField } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { Widgets, WidthFull } from '@mui/icons-material';

function DateRangePicker({ column = false }) {
  const [fromDate, setFromDate] = useState(dayjs());
  const [toDate, setToDate] = useState(dayjs());


  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box sx={{
        display: 'flex',
        flexDirection: column ? 'column' : 'row',
        justifyContent: 'flex-end',
        width: '100%',
        gap: column ? 1.5 : 1,
        '@media (max-width: 800px)': {
          flexDirection: column ? 'column' : 'row',
          gap: column ? 1.5 : 1
        },
      }}>
        <DatePicker
          slotProps={{ textField: { size: 'small' } }}
          sx={{
            flexGrow: 1,
            // paddingLeft: 1,
            '@media (max-width: 800px)': {
              paddingX: 1
            }
          }}
          format='DD/MM/YY'
          label="From"
          value={fromDate}
          onChange={(e) => {
            console.log('e', e);
            // get current day:
            console.log('dayjs()', dayjs());
            setFromDate(e);
          }}
          renderInput={(props) => <TextField {...props} />}
        />
        <DatePicker
          slotProps={{ textField: { size: 'small' } }}
          sx={{
            flexGrow: 1,
            // paddingRight: 1,
            '@media (max-width: 800px)': {
              paddingX: 1
            }
          }}
          label="To"
          value={toDate}
          format='DD/MM/YY'
          onChange={setToDate}
          renderInput={(props) => <TextField {...props} />}
        />
      </Box>
    </LocalizationProvider>
  );
}

export default DateRangePicker;
