import { Box, Container, Divider, Grid, Paper, Typography } from "@mui/material";
import SideNav from "../../components/SideNav/SideNav";
import CustomCalendar from "../../components/CustomCalendar/CustomCalendar";
import DropSelect from "../../components/DropSelect/DropSelect";
import HorizontalBars from "../../components/HorizontalBars/HorizontalBars";
import MediaTable from "../../components/MediaTable/MediaTable";
import SummaryTable from "../../components/SummaryTable/SummaryTable";
import DateRangePicker from "../../components/DateRangePicker/DateRangePicker";
import Keywords from "../../components/Keywords/Keywords";
import MultiAxisLineChart from "../../components/LineChart/LineChart";
import theme from "../../constants/theme";
import rootStore from "../../store/rootStore";
import SummaryCard from "../../components/SummaryCard/SummaryCard";
import { observer } from "mobx-react";
import ShareOfVoice from "../../components/ShareOfVoice/ShareOfVoice";
import DonutChart from "../../components/DonutChart/DonutChart";
import ActiveCampaign from "../../components/ActiveCampaign/ActiveCampaign";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";

function Welcome() {
  const location = useLocation();
  console.log('location', location);
  console.log('USER: ', rootStore.currentUser)

  return (
      <Container container sx={{
        display: 'grid',
        paddingTop: 2,
        // display: 'flex',
        // flexDirection: 'column',
        gap: 1.2,
        maxWidth: '100%',
        gridTemplateAreas: `
        'welcome welcome welcome activeCampaign activeCampaign'
        'summary summary summary activeCampaign activeCampaign'
        'summary summary summary shareOfVoice shareOfVoice'
        'summary summary summary shareOfVoice shareOfVoice'
        'competitorSummary competitorSummary competitorSummary shareOfVoice shareOfVoice'
        'mostActiveMediaOutlet mostActiveMediaOutlet mostActiveMediaOutlet sentiment sentiment'
        'mostActiveMediaOutlet mostActiveMediaOutlet mostActiveMediaOutlet sentiment sentiment'
        'mostEngagingOutletOnFb mostEngagingOutletOnFb mostEngagingOutletOnFb mostEngagingOutletOnFb mostEngagingOutletOnFb'
        `,
        '@media (max-width: 1300px)': {
          gridTemplateAreas: rootStore.isDrawerOpen ? `
          'welcome welcome welcome activeCampaign activeCampaign'
          'summary summary summary activeCampaign activeCampaign'
          'summary summary summary shareOfVoice shareOfVoice'
          'competitorSummary competitorSummary competitorSummary shareOfVoice shareOfVoice'
          'mostActiveMediaOutlet mostActiveMediaOutlet mostActiveMediaOutlet sentiment sentiment'
          'mostActiveMediaOutlet mostActiveMediaOutlet mostActiveMediaOutlet sentiment sentiment'
          'mostEngagingOutletOnFb mostEngagingOutletOnFb mostEngagingOutletOnFb mostEngagingOutletOnFb mostEngagingOutletOnFb'
          ` : `
          'welcome welcome activeCampaign activeCampaign'
          'summary summary activeCampaign activeCampaign'
          'summary summary shareOfVoice shareOfVoice'
          'competitorSummary competitorSummary shareOfVoice shareOfVoice'
          'mostActiveMediaOutlet mostActiveMediaOutlet sentiment sentiment'
          'mostActiveMediaOutlet mostActiveMediaOutlet sentiment sentiment'
          'mostEngagingOutletOnFb mostEngagingOutletOnFb mostEngagingOutletOnFb mostEngagingOutletOnFb'
          `,
        },
        '@media (max-width: 1200px)': {
          gridTemplateAreas: `
          'welcome activeCampaign activeCampaign'
          'welcome activeCampaign activeCampaign'
          'summary summary summary'
          'competitorSummary competitorSummary competitorSummary'
          'shareOfVoice shareOfVoice shareOfVoice'
          'sentiment sentiment sentiment'
          'mostActiveMediaOutlet mostActiveMediaOutlet mostActiveMediaOutlet'
          'mostEngagingOutletOnFb mostEngagingOutletOnFb mostEngagingOutletOnFb'
          `,
        },
        '@media (max-width: 600px)': {
          maxWidth: '100vw',
          gridTemplateAreas: `
          'welcome'
          'welcome'
          'activeCampaign'
          'summary'
          'competitorSummary'
          'shareOfVoice'
          'sentiment'
          'mostActiveMediaOutlet'
          'mostEngagingOutletOnFb'
          `,
        },
      }}>
        {/* <Grid container spacing={1} justifyContent={'space-between'}> */}
          <Grid item sx={{
          gridArea: 'welcome',
        }}>
            <Paper sx={{
              display: 'flex',
              flexDirection: 'column',
              p: 1.5,
              gap: 1,
            }}>
              <Box>
                <Typography variant='subtitle1'>Hello User this is a real time tracker for:</Typography>
              </Box>
              <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                '@media (max-width: 600px)': {
                  flexDirection: 'column',
                  gap: 2
                },
              }}>
                <DropSelect keywordGroups={rootStore.keywordGroups} />
                <DateRangePicker />
              </Box>
            </Paper>
          </Grid>
          {/* <Grid item sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
            gridArea: 'datePicker',
          }}>
            <Paper sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              gap: 1,
              paddingY: 2,
              height: '100%'
            }}>
              <Typography variant='subtitle1'></Typography>
              <DateRangePicker />
            </Paper>
          </Grid> */}
          <Grid item sx={{
            gridArea: 'activeCampaign',
          }}>
            <ActiveCampaign 
                campaignName={rootStore.selectedKeywordGroup.groupName}
                startDate={rootStore.selectedKeywordGroup.dateRange[0].toISOString().split('T')[0].replace(/-/g, '.')}
                endDate={rootStore.selectedKeywordGroup.dateRange[1].toISOString().split('T')[0].replace(/-/g, '.')}
                articles={rootStore.selectedKeywordGroup.totalArticles.value}
                mentions={rootStore.selectedKeywordGroup.totalMentions.value}
                socialPosts={rootStore.selectedKeywordGroup.totalSocialPosts.value}
              />
          </Grid>
        <Grid item sx={{
          gridArea: 'summary',
          minHeight: '100%',
        }}>
          <Paper sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            gap: 2,
            paddingY: 2,
          }}>
            <Typography variant="h4" paddingLeft={2}>
              Summary
            </Typography>
            <Divider mb={2} />
            <Grid container spacing={1.5} padding={2}>
              {/* Articles, Mentions, Social posts */}
              <Grid item xs={12} md={4}>
                <SummaryCard 
                  title={rootStore.selectedKeywordGroup.totalArticles.title} 
                  number={rootStore.selectedKeywordGroup.totalArticles.value} 
                  percentage={rootStore.selectedKeywordGroup.totalArticles.percentage}
                  backgroundColor={theme.customColors.rajah}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <SummaryCard 
                  title={rootStore.selectedKeywordGroup.totalMentions.title} 
                  number={rootStore.selectedKeywordGroup.totalMentions.value} 
                  percentage={rootStore.selectedKeywordGroup.totalMentions.percentage}
                  backgroundColor={theme.customColors.rajah}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <SummaryCard 
                  title={rootStore.selectedKeywordGroup.totalSocialPosts.title} 
                  number={rootStore.selectedKeywordGroup.totalSocialPosts.value} 
                  percentage={rootStore.selectedKeywordGroup.totalSocialPosts.percentage}
                  backgroundColor={theme.customColors.rajah}
                />
              </Grid>
              {/* likes, engagements, comments */}
              <Grid item xs={12} md={4}>
                <SummaryCard 
                  title={rootStore.selectedKeywordGroup.totalLikes.title} 
                  number={rootStore.selectedKeywordGroup.totalLikes.value} 
                  percentage={rootStore.selectedKeywordGroup.totalLikes.percentage}
                  backgroundColor={theme.customColors.dustStorm}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <SummaryCard 
                  title={rootStore.selectedKeywordGroup.totalEngagements.title} 
                  number={rootStore.selectedKeywordGroup.totalEngagements.value} 
                  percentage={rootStore.selectedKeywordGroup.totalEngagements.percentage}
                  backgroundColor={theme.customColors.dustStorm}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <SummaryCard 
                  title={rootStore.selectedKeywordGroup.totalComments.title} 
                  number={rootStore.selectedKeywordGroup.totalComments.value} 
                  percentage={rootStore.selectedKeywordGroup.totalComments.percentage}
                  backgroundColor={theme.customColors.dustStorm}
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
          <Grid item sx={{
            gridArea: 'competitorSummary',
          }}>
            <Paper sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              gap: 2,
              paddingY: 2,
            }}>
              <Typography variant="h4" paddingLeft={2}>
                Competitor Summary
              </Typography>
              <Divider mb={2} />
              <Grid container spacing={1.5} padding={2}>
                {/* Articles, Mentions, Social posts */}
                <Grid item xs={12} md={4}>
                  <SummaryCard 
                    title={rootStore.selectedKeywordGroup.totalCompetitorArticles.title} 
                    number={rootStore.selectedKeywordGroup.totalCompetitorArticles.value} 
                    percentage={rootStore.selectedKeywordGroup.totalCompetitorArticles.percentage}
                    backgroundColor={theme.customColors.dustStorm}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <SummaryCard 
                    title={rootStore.selectedKeywordGroup.totalCompetitorMentions.title} 
                    number={rootStore.selectedKeywordGroup.totalCompetitorMentions.value} 
                    percentage={rootStore.selectedKeywordGroup.totalCompetitorMentions.percentage}
                    backgroundColor={theme.customColors.dustStorm}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                <SummaryCard 
                  title={rootStore.selectedKeywordGroup.totalCompetitorSocialPosts.title} 
                  number={rootStore.selectedKeywordGroup.totalCompetitorSocialPosts.value} 
                  percentage={rootStore.selectedKeywordGroup.totalCompetitorSocialPosts.percentage}
                  backgroundColor={theme.customColors.dustStorm}
                />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item sx={{
            gridArea: 'shareOfVoice',
          }}>
            <Paper sx={{
              overflow: 'hidden',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',
            }}>
              <ShareOfVoice data={rootStore.selectedKeywordGroup.shareOfVoice} />
            </Paper>
          </Grid>
          <Grid item sx={{
            gridArea: 'sentiment',
          }}>
            <Paper sx={{
              overflow: 'hidden',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',
            }}>
              <DonutChart sentimentData={rootStore.selectedKeywordGroup.sentiment} sentimentScore={rootStore.selectedKeywordGroup.sentimentScore} />
            </Paper>
          </Grid>
          <Paper item sx={{
            gridArea: 'mostActiveMediaOutlet'
          }}>
            <HorizontalBars showTitle data={rootStore.selectedKeywordGroup.mediaOutletsData} />
          </Paper>
          <Paper item sx={{
            gridArea: 'mostEngagingOutletOnFb'
          }}>
            <HorizontalBars showTitle data={rootStore.selectedKeywordGroup.mostEngagingOutletOnFb} />
          </Paper>
      </Container>
  );
}

export default observer(Welcome);
