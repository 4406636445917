import React from 'react';
import { ThemeProvider, Typography, Button, Box } from '@mui/material';
import theme from '../../constants/theme';

const StyleGuide = () => {
  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', p: 2, gap: 1 }}>
        <Typography variant="h1">H1 Heading</Typography>
        <Typography variant="h2">H2 Heading</Typography>
        <Typography variant="h3">H3 Heading</Typography>
        <Typography variant="h4">H4 Heading</Typography>
        <Typography variant="h5">H5 Heading</Typography>
        <Typography variant="h6">H6 Heading</Typography>
        <Typography variant="body1">Body 1 text</Typography>
        <Typography variant="body2">Body 2 text</Typography>
        <Button variant="contained" color="primary">
          Primary Button
        </Button>
        <Button variant="outlined" color="primary">
          Primary Button Outlined
        </Button>
        <Button variant="contained" color="secondary">
          Secondary Button
        </Button>
        <Button variant="outlined" color="secondary">
          Secondary Button Outlined
        </Button>
        <Button variant="outlined" color="error">
          Error Button
        </Button>
        <Button variant="outlined" color="success">
          Success Button
        </Button>
        {/* info, not done, warning */}
        <Button variant="outlined" color="info">
          Info Button
        </Button>
        <Button variant="outlined" color="notdone">
          Not Done Button
        </Button>
        <Button variant="outlined" color="warning">
          Warning Button
        </Button>
      </Box>
    </ThemeProvider>
  );
};

export default StyleGuide;
