import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Container, FormControl, Grid, InputLabel, MenuItem, Paper, Select } from '@mui/material';
import { DataGrid, useGridApiEventHandler, useGridApiRef } from '@mui/x-data-grid';
import { useCallback, useState } from 'react';
import { observer } from 'mobx-react';
import rootStore from '../../store/rootStore';
import { useMemo } from 'react';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import CommentIcon from '@mui/icons-material/Comment';
import ShareIcon from '@mui/icons-material/Share';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import SendIcon from '@mui/icons-material/Send';
import Popper from '@mui/material/Popper';
import { GridRowParams } from '@mui/x-data-grid';
import { ConnectWithoutContactOutlined } from '@mui/icons-material';
import { set } from 'mobx';


function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default observer(function MediaTable({ rows, defaultRows = 5, showCompetitors = false, showKeywords = true }) {
  // const rows = rootStore.selectedKeywordGroup.mediaTableData;

  const apiRef = useGridApiRef();

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const renderCell = useCallback((params) => {
    // console.log("RENDER CELL", params)
    if (!params.row.likes) return (<Container></Container>)
    return (
      <Grid container spacing={4} sx={{
        gap: 2,
        padding: 'auto',
        margin: 'auto',
        width: '100%',
      }}>
      <Grid item xs={2} sx={{
        marginRight: 5,
        padding: '0 !important',
        // minWidth: '50px'
      }}>
        <Typography sx={{
        // align icon and text in center/middle
        display: 'flex',
        alignItems: 'center',
        gap: 1,
        }}>
        <FavoriteBorderOutlinedIcon /> {params.row.likes}
        </Typography>
      </Grid>
      <Grid item xs={2} sx={{
        marginRight: 5,
        padding: '0 !important',
        // minWidth: '50px'
      }}>
        <Typography sx={{
        // align icon and text in center/middle
        display: 'flex',
        alignItems: 'center',
        gap: 1,
        }}>
        <ChatBubbleOutlineOutlinedIcon /> {params.row.comments}
        </Typography>
      </Grid>
      <Grid item xs={2} sx={{
        marginRight: 5,
        padding: '0 !important',
        // minWidth: '50px'
      }}>
        <Typography sx={{
        // align icon and text in center/middle
        display: 'flex',
        alignItems: 'center',
        gap: 1,
        }}>
        <SendOutlinedIcon /> {params.row.shares}
        </Typography>
      </Grid>
      <Grid item xs={2} sx={{
        marginRight: 5,
        padding: '0 !important',
        // minWidth: '50px'
      }}>
        <Typography sx={{
        // align icon and text in center/middle
        display: 'flex',
        alignItems: 'center',
        gap: 1,
        }}>
        <ConnectWithoutContactOutlined /> {params.row.engagements}
        </Typography>
      </Grid>
      </Grid>
    )
  }, []);


  const columns = useMemo(() => [
      { field: 'sourceName', headerName: <strong>Source</strong>, flex: 1.4, renderCell: (params) => (
        <div style={{ whiteSpace: 'normal', width: '100%' }}>
          {params.value}
        </div>
      )},
      // { field: 'type', headerName: 'Type', flex: 1}, // Add 'hide: true' to hide the column
      { field: 'date', headerName: <strong>Date</strong>},
      { field: 'title', headerName: <strong>Title</strong>, flex: 3, renderCell: (params) => (
        <div style={{ whiteSpace: 'normal' }}>{params.value}</div> 
      )},
      // { field: 'engagements', headerName: 'Engagements', flex: 1},
      { field: 'social', headerName: <strong>Social</strong>, width: '100%',
      backgroundColor: 'red', flex: 2, renderCell: renderCell },
      { field: 'keywords', headerName: <strong>Keywords</strong>, flex: 1.5, renderCell: (params) => (
        <div style={{ whiteSpace: 'normal' }}>
          {
            params?.value?.join(', ')
          }
        </div>
      )},
    ],
    [renderCell, showKeywords]
  );

  const handleRowClick = (params) => { // otvora nov prozor na rowClick
    window.open(params.row.sourceUrl);
  }

  
  const [popperAnchorEl, setPopperAnchorEl] = useState(null);
  const [hoveredRow, setHoveredRow] = useState(null);

  const handleRowMouseEnter = (event) => {
    console.log('handleRowMouseEnter', event.row);
    setPopperAnchorEl(event.currentTarget);
    setHoveredRow({
      sourceName: event.currentTarget.querySelector('[data-field="sourceName"]').textContent,
      date: event.currentTarget.querySelector('[data-field="date"]').textContent,
      title: event.currentTarget.querySelector('[data-field="title"]').textContent,
      engagements: event.currentTarget.querySelector('[data-field="engagements"]').textContent,
      social: event.currentTarget.querySelector('[data-field="social"]').innerHTML,
    });
  };

  const handleRowMouseLeave = () => {
    setPopperAnchorEl(null);
    setHoveredRow(null);
  };

  const getRowHeight = React.useCallback(() => 80, []);

  const selectNewCompetitor = (event) => {
    console.log('selectNewCompetitor', event.target.value);
    // selectedCompetitor index:
    const index = rootStore.selectedKeywordGroup.competitors.indexOf(event.target.value);
    rootStore.setSelectedCompetitor(index-1);
  }


  return (
    <Paper sx={{ width: '100%', minHeight: '100%', overflowX: 'auto' }}>
      <Box sx={{ display: 'flex', flexWrap: 'wrap', borderBottom: 1, borderColor: 'divider', gap: 1 }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="All" {...a11yProps(0)} />
          <Tab label="Media" {...a11yProps(1)} />
          <Tab label="Social" {...a11yProps(2)} />
        </Tabs>
        {
          showCompetitors &&
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Typography variant="body1">Competitors: </Typography>
            <FormControl size="small" sx={{
              minWidth: 'max-content'
            }}>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Competitors"
                onChange={selectNewCompetitor}
                defaultValue={"All"}
              >
                {
                  ['All', ...rootStore.selectedKeywordGroup.competitors].map(competitor => (
                    <MenuItem key={competitor} value={competitor}>{competitor}</MenuItem>
                  ))
                }
              </Select>
            </FormControl>
          </Box>
        }
      </Box>
      <CustomTabPanel sx={{
        '.MuiBox-root': {
          padding: 0,
        }
      }} value={value} index={0}>
        <div style={{ width: '100%' }}>
          <DataGrid 
            sx={{
              '& .MuiDataGrid-row:hover': {
                color: 'secondary.main',
                backgroundColor: 'rgba(0, 0, 0, 0.04)',
              },
              '& .MuiDataGrid-row': {
                '&:nth-of-type(even)': {
                  backgroundColor: 'rgba(244,244,244, .5)',
                },
                '&:hover': {
                  backgroundColor: 'rgba(0, 0, 0, 0.04)',
                }
              },
            }}
            onRowClick={handleRowClick} 
            getRowHeight={getRowHeight} 
            getrowWidth={'100%'}
            rows={rows}
            columns={columns}
            autoHeight
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: defaultRows,
                },
                pageSizeOptions: [5, 10, 20],
              },
              columns: {
                columnVisibilityModel: {
                  keywords: showKeywords
                },
              },
            }}
          />
        </div>
      </CustomTabPanel>
      <CustomTabPanel sx={{
        '.MuiBox-root': {
          padding: 0,
        }
      }} value={value} index={1}>
        <div style={{ width: '100%' }}>
          <DataGrid sx={{
          '& .MuiDataGrid-row:hover': {
            color: 'secondary.main',
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
          },
          '& .MuiDataGrid-row': {
            '&:nth-of-type(even)': {
              backgroundColor: 'rgba(244,244,244, .5)',
            },
            '&:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.04)',
            }
          },
        }}
            // componentsProps={{
            //   row: {
            //     onMouseEnter: handleRowMouseEnter,
            //     onMouseLeave: handleRowMouseLeave,
            //   }
            // }}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: defaultRows,
                },
                pageSizeOptions: [5, 10, 20],
              },
            }} onRowClick={handleRowClick} getRowHeight={getRowHeight} 
            rows={rows.filter(row => row.type === 'media')} 
            columns={columns}
            getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? 'dark-row' : '')}
             />
        </div>
      </CustomTabPanel>
      <CustomTabPanel sx={{
        '.MuiBox-root': {
          padding: 0,
        }
      }} value={value} index={2}>
        <div style={{ width: '100%' }}>
          <DataGrid sx={{
          '& .MuiDataGrid-row:hover': {
            color: 'secondary.main',
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
          },
          '& .MuiDataGrid-row': {
            '&:nth-of-type(even)': {
              backgroundColor: 'rgba(244,244,244, .5)',
            },
            '&:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.04)',
            }
          },
        }}
            // componentsProps={{
            //   row: {
            //     onMouseEnter: handleRowMouseEnter,
            //     onMouseLeave: handleRowMouseLeave,
            //   }
            // }}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: defaultRows,
                },
                pageSizeOptions: [5, 10, 20],
              },
            }} onRowClick={handleRowClick} getRowHeight={getRowHeight} 
            rows={rows.filter(row => row.type === 'social')} 
            columns={columns} />
        </div>
      </CustomTabPanel>
      <Popper open={Boolean(popperAnchorEl)} anchorEl={popperAnchorEl}>
        <Paper elevation={7} sx={{
          padding: 2,
          width: 280,
        }}>
          <Typography variant="body1">Source: {hoveredRow?.sourceName}</Typography>
          <Typography variant="body1">Date: {hoveredRow?.date}</Typography>
          <Typography variant="body1">Title: {hoveredRow?.title}</Typography>
          <Typography variant="body1">{hoveredRow?.engagements ? `Engagements: ${hoveredRow.engagements}` : ''}</Typography>
          {/* <Typography variant="body1">{hoveredRow?.social}</Typography> */}
        </Paper>
      </Popper>
    </Paper>
  );
});
