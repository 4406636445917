import * as React from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Box } from '@mui/material';
import rootStore from '../../store/rootStore';
import { observer } from 'mobx-react';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default observer(function DropSelect() {
  const [keywordGroup, setKeywordGroup] = React.useState([]);
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setKeywordGroup(typeof value === 'string' ? value.split(',') : value);
    rootStore.setSelectedKeywordGroup(value);
  };

  return (
    <Box sx={{
      width: '100%',
    }}>
      <FormControl
        size="small"
        sx={{ 
          minWidth: 280, 
          width: '100%',
          // media
          '@media (max-width: 600px)': {
            minWidth: '100%',
            width: '100%',
          },
        }}>
        <Select
          fullWidth
          displayEmpty
          value={keywordGroup}
          onChange={handleChange}
          input={<OutlinedInput />}
          renderValue={(selected) => {
            if (selected.length === 0) {
              // return <em>Select a keyword group</em>;
              return <em>{rootStore.keywordGroups[0].groupName}</em>;
            }

            return selected.join(', ');
          }}
          MenuProps={MenuProps}
          inputProps={{ 'aria-label': 'Without label' }}
        >
          {rootStore.keywordGroups.map((group, index) => (
            <MenuItem
              key={group.groupName}
              value={group.groupName}
            >
              {group.groupName}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
});
