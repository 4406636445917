import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';
import SideNav from '../SideNav/SideNav';
import { useState, useEffect, useRef } from 'react'; // Add missing import
import { Drawer } from '@mui/material';
import rootStore from '../../store/rootStore';


export default function HeaderBar() {
  const [isSideNavOpen, setIsSideNavOpen] = useState(true);
  const sideNavRef = useRef(null); // Create a ref for the SideNav component

  const openSideNav = () => {
    setIsSideNavOpen(true);
    rootStore.isDrawerOpen = true;
  };

  const toggleSideNav = () => {
    setIsSideNavOpen(!isSideNavOpen);
    rootStore.toggleDrawer();
  }

  const closeSideNav = () => {
    setIsSideNavOpen(false);
    rootStore.isDrawerOpen = false;
  }

  const isMobileView = window.innerWidth < 601;

  useEffect(() => {
    if (isMobileView) {
      closeSideNav();
    } else {
      openSideNav();
    }
  }, [isMobileView]);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="fixed" sx={{
        borderRadius: 0
      }}>
        <Toolbar
          sx={{
            marginLeft: isSideNavOpen ? '250px' : 0,
            transition: '0.2s ease-in-out',
          }}
        >
          <IconButton
            size="large"
            edge="start"
            aria-label="open drawer"
            sx={{ color: 'white' }}
            onClick={toggleSideNav}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ display: { xs: 'none', sm: 'block' } }}
          >
            DURBIN
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
        </Toolbar>
      </AppBar>
      <Drawer
        variant={isMobileView ? 'default' : 'persistent'}
        sx={{
          '& .MuiDrawer-paper': {
            width: 250,
            backgroundColor: '#F4F4F4',
            borderRadius: 0,
          },
        }}
        anchor="left"
        open={isSideNavOpen}
        ref={sideNavRef}
        onClose={closeSideNav}
      >
        <SideNav />
      </Drawer>
    </Box>
  );
}