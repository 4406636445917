import * as React from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import { Avatar, Typography } from '@mui/material';

import routes from '../../store/routes';
// import Logo from '../../assets/Logo.png';
import Logo from '../../assets/durbin 3-02-dustStorm.svg'
import { CompareArrowsOutlined, HomeOutlined, QueryStatsOutlined } from '@mui/icons-material';
import SummarizeOutlinedIcon from '@mui/icons-material/SummarizeOutlined';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

function SideNav({
  isOpen
}) {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    console.log("location: ", location.pathname);
  
  }, [location.pathname]);

  return (
    <Box
      sx={{
        width: 250,
        height: '100vh',
        position: 'fixed',
        boxShadow: '0 0 15px rgba(0, 0, 0, 0.5)',
        backgroundColor: '#dc828f',
        color: '#fff !important'
      }}
      role="presentation"
    >
      <Box
        item
        sx={{
          mt: 2,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Avatar
          variant='square'
          alt="Durbin Logo"
          src={Logo}
          sx={{ 
            width: 250,
            height: 150,
            cursor: 'pointer',
            objectFit: 'contain',
          }}
          onClick={() => {
            window.location.href = '/';
          }}
        />
        {/* <Typography variant='h4'>Durbin</Typography> */}
      </Box>
      <List>
        {routes.map((routeItem, index) => (
          <ListItem onClick={() => {
            // window.location.href = routeItem.path;
            navigate(routeItem.path);
          }} key={routeItem.name} disablePadding>
            {
              (routeItem.path === location.pathname) || (routeItem.path === '/' && window.location.pathname === '/welcome') ?
              <ListItemButton sx={{
                backgroundColor: '#0000001f',
              }}>
                <ListItemIcon>
                  {
                    routeItem.name === 'Welcome' ? <HomeOutlined sx={{
                      color: '#f2f2f2'
                    }} /> :
                    routeItem.name === 'Keyword Tracker' ? <QueryStatsOutlined sx={{
                      color: '#f2f2f2'
                    }} /> :
                    routeItem.name === 'Competitors' ? <CompareArrowsOutlined sx={{
                      color: '#f2f2f2'
                    }} /> :
                    routeItem.name === 'Analytics' ? <QueryStatsOutlined sx={{
                      color: '#f2f2f2'
                    }} /> : 
                    routeItem.name === 'Reports' ? <SummarizeOutlinedIcon sx={{
                      color: '#f2f2f2'
                    }} /> : null
                  }
                </ListItemIcon>
                <ListItemText 
                  primary={routeItem.name} 
                  sx={{ '& .MuiListItemText-primary': { fontWeight: 700 } }} 
                />
              </ListItemButton> :
              <ListItemButton>
                <ListItemIcon>
                  {
                    routeItem.name === 'Welcome' ? <HomeOutlined sx={{
                      color: '#f2f2f2'
                    }} /> :
                    routeItem.name === 'Keyword Tracker' ? <QueryStatsOutlined sx={{
                      color: '#f2f2f2'
                    }} /> :
                    routeItem.name === 'Competitors' ? <CompareArrowsOutlined sx={{
                      color: '#f2f2f2'
                    }} /> :
                    routeItem.name === 'Analytics' ? <QueryStatsOutlined sx={{
                      color: '#f2f2f2'
                    }} /> :
                    routeItem.name === 'Reports' ? <SummarizeOutlinedIcon sx={{
                      color: '#f2f2f2'
                    }} /> : null
                  }
                </ListItemIcon>
                <ListItemText primary={routeItem.name} />
              </ListItemButton>
            }
          </ListItem>
        ))}
      </List>
      <Divider />
      {/* <Box>
        <Typography>
          some sidenav footer stuff, social media links, logo etc.
        </Typography>
      </Box> */}
    </Box>
  );
}

export default SideNav;