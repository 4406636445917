import * as React from 'react';
import { BarChart } from '@mui/x-charts/BarChart';

// import './HorizontalBars.css';
import { Paper, Box, Typography, Divider } from '@mui/material';

import { useTheme } from '@mui/material/styles';


const valueFormatter = (value) => `${value}`;

export default function VerticalBars({ data, showTitle = false }) {
  const theme = useTheme();

  console.log('HorizontalBars data:', data.layout == "vertical");

  const chartSetting = { 
    yAxis: [
      {
        label: data.title,
      },
    ],
    height: 400,
  };

  return (
    <Paper sx={{
      display: 'flex',
      flexDirection: 'column',
      minHeight: 412,
    }} className='HorizontalBars'>
      {showTitle ? 
      <>
        <Box sx={{
          padding: '1rem'
        }}>
          <Typography variant="h4">
            {data.title}
          </Typography>
        </Box>
        <Divider sx={{
          width: '100%'
        }} />
      </> : ''}
      <BarChart
        sort={data.sort || [{ dataKey: 'engagements', order: 'desc' }]}
        dataset={data.data}
        xAxis={data.xAxis}
        series={data.series}
        layout={data.layout || 'vertical'}
        {...chartSetting}
        // margin={{ left: 100 }}
        sx={{
          maxHeight: 400
        }}
        colors={[
          theme.customColors.newYorkPink,
          theme.customColors.barleyCorn,
          theme.customColors.dustStorm,
          theme.customColors.rajah,
          theme.customColors.mountbattenPink
        ]}
      />
    </Paper>
  );
}