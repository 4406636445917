const currentuser = {
  id: 1,
  username: 'password',
  first_name: 'Boban',
  last_name: 'Prezimevski',
  password: 'username',
  email: 'guardsmanBob@mail4life.com'
}

// topics vrzani so user, kombinacija koj topic vo koj kanal e najden i od koj source

const newsOutletsData = [
  {
    newsSource: 'Sloboden Pecat',
    views: 59,
    keywords: 5,
    dateCollected: '2024-10-01',
  },
  {
    newsSource: 'Republika',
    views: 57,
    keywords: 3,
    dateCollected: '2024-10-01',
  },
  {
    newsSource: 'Info.mk',
    views: 86,
    keywords: 8,
    dateCollected: '2024-10-01',
  }
];

export const trackerSummaryTable = {
  rows: [
    { id: 1, source: 'Media', posts: 37, likes: 79, shares: 62, comments: 21, views: '100', engagements: 162 },
    { id: 2, source: 'Facebook', posts: 14, likes: 1076, shares: 95, comments: 19, views: null, engagements: 1354 },
    { id: 3, source: 'Twitter', posts: 10, likes: 20, shares: 2, comments: '', views: undefined, engagements: 22 },
    { id: 4, source: 'Youtube', posts: 0, likes: 0, shares: 0, comments: 0, views: null, engagements: 0 },
    { id: 5, source: 'Instagram', posts: 1, likes: 587, shares: 56, comments: 20, views: 0, engagements: 42 },
    { id: 6, source: 'LinkedIn', posts: 1, likes: 34, shares: 4, comments: 12, views: '-', engagements: 23 },
  ]
}

const mediaSocialBoth = [
  {
    sourceName: 'pari.com.mk',
    sourceType: 'media',
  }
]

export default newsOutletsData;