import React from 'react';
import { Box, Card, CardContent, Divider, IconButton, Tooltip, Typography } from '@mui/material';

import { useTheme } from '@mui/material/styles';
import SummaryCard from '../SummaryCard/SummaryCard';
import { DateRangeIcon } from '@mui/x-date-pickers';
import dayjs from 'dayjs';


const ActiveCampaign = ({ campaignName, startDate, endDate, mentions, articles, socialPosts }) => {
  const theme = useTheme();

  return (
    <Card style={{
      backgroundColor: theme.customColors.barleyCorn,
      height: '100%',
      color: 'white',
      textShadow: '1px 1px 10px rgba(0, 0, 0, 0.2)',
    }}>
      <Typography variant="h4" padding={2}>
        Active Campaign
      </Typography>
      <Divider />
      <CardContent>
        <Box>
          <Typography variant="h5" component="div">
            {campaignName}
          </Typography>
          {/* <Typography variant="subtitle1" color="text.secondary">
            {startDate} - {endDate}
          </Typography> */}
          <Typography variant="subtitle1">
            <Tooltip title={dayjs(startDate).format('MMMM D, YYYY')} placement="top">
              <IconButton>
                <DateRangeIcon sx={{
                  color: theme.customColors.dustStorm
                }} />
              </IconButton>
            </Tooltip>
            {dayjs(startDate).format('MMMM D, YYYY')}
            <br />
            <Tooltip title={dayjs(endDate).format('MMMM D, YYYY')} placement="top">
              <IconButton>
                <DateRangeIcon sx={{
                  color: theme.customColors.dustStorm
                }} />
              </IconButton>
            </Tooltip>
            {dayjs(endDate).format('MMMM D, YYYY')}
          </Typography>
        </Box>
        <Box 
          display="flex" 
          flexDirection="row"
          sx={{
            marginTop: 1,
            alignItems: 'center',
            justifyContent: 'center',
            gap: 1,
            '@media (max-width: 600px)': {
              flexDirection: 'column',
              alignItems: 'stretch',
              gap: 1,
            }
          }}
        >
          <Box>
            <SummaryCard title="Mentions" number={mentions} backgroundColor={theme.customColors.dustStorm} />
          </Box>
          <Box>
            <SummaryCard title="Articles" number={articles} backgroundColor={theme.customColors.dustStorm} />
          </Box>
          <Box>
            <SummaryCard title="Social Posts" number={socialPosts} backgroundColor={theme.customColors.dustStorm} />
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default ActiveCampaign;