
    import React from 'react';
    import { Typography } from '@mui/material';

    const Reports = () => {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-start', minHeight: '100vh' }}>
                
                <Typography variant="body1">
                <Typography variant="h3" gutterBottom>
                    List of analytics:
                </Typography>
                    <ol>
                        <li>
                            Sentiment Analysis
                            <ul>
                                <li>Table: Summary of sentiment scores for different brands or keywords.</li>
                                <li>Pie Chart: Distribution of positive, negative, and neutral sentiments.</li>
                                <li>Line Graph: Sentiment trend over time for specific topics or competitors.</li>
                                <li>Heatmap: Sentiment by geographic location or demographics.</li>
                            </ul>
                        </li>
                        <li>
                            Engagement Metrics
                            <ul>
                                <li>Bar Chart: Comparison of engagement (likes, shares, comments) across different social media platforms.</li>
                                <li>Line Chart: Engagement trends over time for specific campaigns or posts.</li>
                                <li>Scatter Plot: Correlation between post frequency and engagement levels.</li>
                            </ul>
                        </li>
                        <li>
                            Influencer Analysis
                            <ul>
                                <li>Table: List of top influencers mentioning your brand or competitors.</li>
                                <li>Network Graph: Visual representation of influencer networks and their connections.</li>
                                <li>Bar Chart: Influence score comparison among top influencers.</li>
                            </ul>
                        </li>
                        <li>
                            Competitor Performance
                            <ul>
                                <li>Table: Key performance indicators (KPIs) of competitors such as mentions, reach, engagement.</li>
                                <li>Line Graph: Competitor activity over time.</li>
                                <li>Radar Chart: Strengths and weaknesses comparison across multiple metrics.</li>
                            </ul>
                        </li>
                        <li>
                            Trend Analysis
                            <ul>
                                <li>Word Cloud: Visualization of trending keywords and hashtags.</li>
                                <li>Line Chart: Trend lines for specific keywords or topics over time.</li>
                                <li>Heatmap: Geographic distribution of trending topics.</li>
                            </ul>
                        </li>
                        <li>
                            Audience Demographics
                            <ul>
                                <li>Table: Breakdown of audience demographics (age, gender, location).</li>
                                <li>Pie Chart: Percentage distribution of different demographic groups.</li>
                                <li>Bar Chart: Comparison of audience demographics across different platforms.</li>
                            </ul>
                        </li>
                        <li>
                            Share of Voice
                            <ul>
                                <li>Pie Chart: Market share of voice among competitors.</li>
                                <li>Bar Chart: Share of voice comparison for different keywords or topics.</li>
                                <li>Table: Volume of mentions for each competitor.</li>
                            </ul>
                        </li>
                        <li>
                            Campaign Performance
                            <ul>
                                <li>Table: Detailed performance metrics for each campaign (reach, engagement, conversions).</li>
                                <li>Line Graph: Campaign performance over time.</li>
                                <li>Pie Chart: Budget allocation and ROI for different campaigns.</li>
                            </ul>
                        </li>
                        <li>
                            Crisis Management
                            <ul>
                                <li>Table: List of incidents and their impact on brand sentiment.</li>
                                <li>Line Chart: Sentiment recovery timeline post-crisis.</li>
                                <li>Heatmap: Crisis impact by region or demographic.</li>
                            </ul>
                        </li>
                        <li>
                            Content Performance
                            <ul>
                                <li>Bar Chart: Performance of different types of content (videos, images, articles).</li>
                                <li>Table: Top-performing posts and their metrics.</li>
                                <li>Line Graph: Content performance trends over time.</li>
                            </ul>
                        </li>
                        <li>
                            Audience Engagement
                            <ul>
                                <li>Heatmap: Most active times and days for audience engagement.</li>
                                <li>Bar Chart: Engagement by content type or platform.</li>
                                <li>Table: User feedback and comments analysis.</li>
                            </ul>
                        </li>
                        <li>
                            Geographic Analysis
                            <ul>
                                <li>Map Visualization: Mentions and engagement by geographic location.</li>
                                <li>Heatmap: Popularity of topics or brands by region.</li>
                                <li>Table: Regional breakdown of key metrics.</li>
                            </ul>
                        </li>
                        <li>
                            Customer Journey Mapping
                            <ul>
                                <li>Flow Chart: Visualization of customer touchpoints from awareness to conversion.</li>
                                <li>Line Graph: Drop-off points in the customer journey.</li>
                                <li>Pie Chart: Percentage of customers at each stage of the journey.</li>
                            </ul>
                        </li>
                    </ol>
                </Typography>
            </div>
        );
    }

    export default Reports;