import React from 'react';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { observer } from 'mobx-react';

import './Keywords.css';
import rootStore from '../../store/rootStore';
import { Container, Grid } from '@mui/material';

const Keywords = observer(() => {
  const keywords = rootStore.getCurrentKeywords();

  return (
    <Box sx={{
      justifyContent: 'space-between',
    }}>
      <Typography variant="h6" component="h2">
        Keywords tracked:
      </Typography>
      <Grid container sx={{
        gridTemplateColumns: 'repeat(4, 1fr)',
        gap: 1,
      }}>
        {keywords.map((keyword, index) => (
            <Chip sx={{
              '& .MuiChip-root': {
                fontSize: '9px',
              },
            }} className='Keywords-keyword' key={index} label={keyword} color="primary" />
        ))}
      </Grid>
    </Box>
  );
});

export default Keywords;