import React, { useEffect } from 'react';
import { LineChart } from '@mui/x-charts/LineChart';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { Divider } from '@mui/material';

import { useTheme } from '@mui/material/styles';
import { ConstructionOutlined } from '@mui/icons-material';
import { useState } from 'react';

function MultiAxisLineChart({ data, yLabel }) {
  console.log('MultiAxisLineChart data: ', data);

  // Default dummy data
  const defaultData = {
    axis1: [0, 10, 30, 50, 70, 90, 100],
    axis2: [0, 30, 49, 43, 57, 40, 70],
    axis3: [0, 20, 40, 60, 100, 70, 60],
    axis4: [0, 14, 29, 43, 57, 71, 86],
    axis5: [0, 0, 10, 20, 10, 5, 38],
    dateTime: [
      new Date('2021-05-01'),
      new Date('2021-06-02'),
      new Date('2021-07-03'),
      new Date('2021-08-04'),
      new Date('2021-09-05'),
      new Date('2021-10-06'),
      new Date('2021-11-10'),
    ]
  };

  const dummyData = data || defaultData;

  const theme = useTheme();

  // Define your state variable
const [axisValues, setAxisValues] = useState([0, 25, 50, 75, 100]);

// Use the useEffect hook to update axisValues when data is loaded
useEffect(() => {
  if (data) {
    // Find the maximum value in the data
    const maxVal = Math.max(...data.map(item => Math.max(...item.engagements.map(engagement => engagement.value))));

    // Calculate the decrement value
    const decrement = maxVal / 4;

    // Generate the new array
    const newAxisValues = Array.from({length: 5}, (_, i) => Math.round(i * decrement));

    // Update the state variable
    setAxisValues(newAxisValues);
  }
}, [data]);


  const allDates = data.reduce((dates, keywordData) => {
    const engagementDates = keywordData.engagements.map((engagement) => engagement.date);
    return engagementDates;
  }, []);

  const sortedDates = allDates.sort((a, b) => new Date(a) - new Date(b));

  // console.log('sortedDates: ', sortedDates)
  console.log('Keyword: ', data[0].keyword);

  const [dataSeries, setDataSeries] = useState([]);

  const maxKeyword = data.reduce((maxKeyword, keywordData) => {
    const maxValue = Math.max(...keywordData.engagements.map(engagement => engagement.value));
    if (maxValue > maxKeyword.maxValue) {
      maxKeyword.maxValue = maxValue;
      maxKeyword.keyword = keywordData.keyword;
    }
    return maxKeyword;
  }, { maxValue: -Infinity, keyword: '' }).keyword;
  
  return (
    data !== undefined ? <Paper sx={{
      minHeight: 400,
      maxHeight: '50vh',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'stretch',
      gap: 2,
      paddingY: 2,
    }}>
      <Typography variant="h4" align="left" paddingLeft={2}>
        Keyword Engagement
      </Typography>
      <Divider mb={2} />
      <LineChart
        xAxis={[{ data: sortedDates.map(date => new Date(date)), scaleType: 'time', label: 'Date' }]}
        yAxis={[
          { id: data[0].keyword, scaleType: 'linear', label: yLabel },
          { id: data[1].keyword, scaleType: 'linear', label: yLabel },
          { id: data[2].keyword, scaleType: 'linear', label: yLabel },
          { id: data[3].keyword, scaleType: 'linear', label: yLabel },
          { id: data[4].keyword, scaleType: 'linear', label: yLabel },
        ]}
        series={[
          {
            yAxisKey: data[0].keyword, data: data[0].engagements.reduce((accumulator, currentValue) => {
              accumulator.push(currentValue.value);
              return accumulator;
            }, []), label: 'Mastercard'
          },
          {
            yAxisKey: data[1].keyword, data: data[1].engagements.reduce((accumulator, currentValue) => {
              accumulator.push(currentValue.value);
              return accumulator;
            }, []), label: 'Visa'
          },
          {
            yAxisKey: data[2].keyword, data: data[2].engagements.reduce((accumulator, currentValue) => {
              accumulator.push(currentValue.value);
              return accumulator;
            }, []), label: 'PayPal'
          },
          {
            yAxisKey: data[3].keyword, data: data[3].engagements.reduce((accumulator, currentValue) => {
              accumulator.push(currentValue.value);
              return accumulator;
            }, []), label: 'Stripe'
          },
          {
            yAxisKey: data[4].keyword, data: data[4].engagements.reduce((accumulator, currentValue) => {
              accumulator.push(currentValue.value);
              return accumulator;
            }, []), label: 'Square'
          },
        ]}
        leftAxis={maxKeyword}
        rightAxis={maxKeyword}
        colors={[
          theme.customColors.newYorkPink,
          theme.customColors.barleyCorn,
          theme.customColors.dustStorm,
          theme.customColors.rajah,
          theme.customColors.mountbattenPink
        ]}
        hideLeftAxis={true}
      />
    </Paper> : ''
  );
}

export default MultiAxisLineChart;
