import React from 'react';
import { Paper, Typography } from '@mui/material';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import theme from '../../constants/theme'

const SummaryCard = ({ title="some title placeholder", number="9,500,000", percentage="42", backgroundColor="" }) => {
  return (
    <Paper elevation={4} sx={{ p: 1, minWidth: '12ch', flexGrow: 1, cursor: 'default', borderRadius: 2, backgroundColor: backgroundColor === "" ? '' : backgroundColor }}>
      <Typography textAlign="left" variant="h6" sx={{ mb: 1 }}>
        {title}
      </Typography>
      <Typography textAlign={'center'} variant="h4" sx={{ m: 2 }}>
        {number}
      </Typography>
      {/* <Typography
        variant="body1"
        sx={{
          color: percentage >= 0 ? theme.customColors.green : theme.customColors.red, // ova treba da go smenam bojata da doagja od samiot api response
          textAlign: 'center',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        {percentage >= 0 ? (
          <ArrowDropUpIcon />
        ) : (
          <ArrowDropDownIcon />
        )}
        {percentage}%
      </Typography> */}
    </Paper>
  );
};

export default SummaryCard;
